import { createSlice } from '@reduxjs/toolkit';
import { GlobalFunctions } from '../../GlobalFunctions';

export const shareDataSlice = createSlice({
    name: 'shareData',
    initialState: {
        data: {}
    },
    reducers: {
        update: (state, action) => {
            let globalFunctions = new GlobalFunctions();
            state.data = globalFunctions.mergeDeep(state.data, action.payload);
        },
        clear: (state, action) => {
            state.data = {};
        }
    }
});

export const { update, clear } = shareDataSlice.actions;
export const selectShareData = (state) => state[ 'shareData' ];
export const shareDataReducer = shareDataSlice.reducer;
