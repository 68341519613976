import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { GlobalFunctions } from '../../GlobalFunctions';
import style from './FormSelectField.module.scss';

export const FormSelectField: React.FC<{
    value: string,
    isRequire?: boolean,
    label: string,
    className?: string,
    type?: string,
    valueChange?: (value) => any,
    valueValid?: (value) => any,
    option: Array<{
        label: string,
        value: string,
    }>
}> = ({
          value,
          isRequire = false,
          label,
          className = '',
          type = '',
          valueChange = (value) => {
          },
          valueValid = (value) => {
          },
          option
      }) => {
    const [showError, setShowError] = useState(false);
    const [valueValidState, setValueValidState] = useState(null);
    const gf = new GlobalFunctions();

    const dataChange = (event) => {
        setShowError(true);
        valueChange(event.target.value);
        checkValueValid();
    };

    const checkValueValid = () => {
        let status = !gf.fieldValidationError(value, isRequire, type).error;
        if (valueValidState !== status) {
            valueValid(status);
            setValueValidState(status);
        }
    };
    checkValueValid();
    return (
        <div className={`text-input ${className} ${style[ 'form-text-field' ]}`}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"
                            error={showError && gf.fieldValidationError(value, isRequire, type).error}>{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={label}
                    onChange={(e) => dataChange(e)}
                >
                    {option.map((v, i) => {
                        return (<MenuItem value={v.value}>{v.label}</MenuItem>);
                    })}
                </Select>
                <FormHelperText>{showError ? gf.errorMsg(gf.fieldValidationError(value, isRequire, type).messageCode) : ''}</FormHelperText>
            </FormControl>
        </div>
    );
};
