export class GlobalFunctions {
    constructor() {
    }

    isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    mergeDeep(...objects) {
        const isObject = obj => obj && typeof obj === 'object';

        return objects.reduce((prev, obj) => {
            Object.keys(obj).forEach(key => {
                const pVal = prev[ key ];
                const oVal = obj[ key ];

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[ key ] = pVal.concat(...oVal);
                } else if (isObject(pVal) && isObject(oVal)) {
                    prev[ key ] = this.mergeDeep(pVal, oVal);
                } else {
                    prev[ key ] = oVal;
                }
            });

            return prev;
        }, {});
    }

    fieldValidationError(value, isRequire, type?, regex?) {
        let error = false;
        let messageCode = '';
        if (!isRequire && !value) {
            return {
                error: false,
                messageCode: ''
            };
        }
        // console.log(value, !value, isRequire, typeof value);
        if (isRequire) {
            if (typeof value == 'string') {
                if (!value) {
                    return {
                        error: true,
                        messageCode: 'is_require'
                    };
                }
            }
            if (typeof value == 'object') {
                if (!value.length) {
                    return {
                        error: true,
                        messageCode: 'is_require'
                    };
                }
            }
        }
        if (type) {
            switch (type) {
                case 'phone':
                    var reg = /^[0-9]*$/;
                    if (!reg.test(value)) {
                        error = true;
                        messageCode = 'invalid_phone';
                    }
                    break;
                case 'email':
                    var reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    if (!reg.test(value)) {
                        error = true;
                        messageCode = 'invalid_email';
                    }
                    break;
                case 'date':
                    if (value == 'Invalid Date') {
                        error = true;
                        messageCode = 'invalid_date';
                    }
                    break;
            }
        }
        return {
            error: error,
            messageCode: messageCode
        };
    }

    errorMsg(code) {
        let msg = '';
        let allMsg = {
            'is_require': 'Required field.',
            'invalid_phone': 'Invalid phone.',
            'invalid_email': 'Invalid e-mail.',
            'invalid_date': 'Invalid Date.'
        };
        if (allMsg[ code ]) {
            msg = allMsg[ code ];
        }
        return msg;
    }

    /**
     *
     * @param key
     * @param data
     * @param expiry Number of minutes
     */
    setLocalStorage(key, data, expiry?: number) {
        key = this.localStorageKey(key);
        let setData = {};
        if (!expiry) {
            expiry = 9999999999999999;
        }
        setData[ 'expiry' ] = Date.now() + expiry * 60000;

        if (Array.isArray(data) || typeof data === 'object') {
            data = JSON.stringify(data);
        } else {
            data = data.toString();
        }
        setData[ 'data' ] = data;
        window.localStorage.setItem(key, JSON.stringify(setData));
    }

    /**
     *
     * @param key
     */
    getLocalStorage(key) {
        key = this.localStorageKey(key);
        let data = window.localStorage.getItem(key);
        if (data) {
            data = JSON.parse(data);
            if (Number.isInteger(data[ 'expiry' ])) {
                if (Date.now() > data[ 'expiry' ]) {
                    window.localStorage.setItem(key, '');
                    return false;
                }
            } else {
                window.localStorage.setItem(key, '');
                return false;
            }
            data = data[ 'data' ];
            if (this.isJson(data)) {
                data = JSON.parse(data);
            }
            return data;

        }
        return false;
    }

    localStorageKey(key) {
        return 'app-' + key;
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    search(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[ i ].name === nameKey) {
                return myArray[ i ];
            }
        }
        return false;
    }

    randomString(length, possible?) {
        var text = '';
        if (!possible) {
            possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        }

        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }
}
