import { InputAdornment } from '@material-ui/core';
import { Modal, Steps } from 'antd';
import { useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectStepData, updateChannel, updateSponsor } from '../../store/slice/stepData';
import './Sponsor.scss';
import { FormButtonOption } from '../FormButtonOption/FormButtonOption';
import { FormSelectField } from '../FormSelectField/FormSelectField';
import { FormTextField } from '../FormTextField/FormTextField';
import { FormToggle } from '../FormToggle/FormToggle';
import { IconButton } from '../IconButton/IconButton';

export const Sponsor: React.FC<{
    sponsorIndex: number,
    valueValid?: (value) => any
    deleteSponsor?: (value) => any
}> = ({
          sponsorIndex,
          valueValid = (value) => {
          },
          deleteSponsor = (value) => {
          }
      }) => {
    const [formValid, setFormValid] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();

    const dataChange = (value, fieldName, subFieldName?) => {
        dispatch(updateSponsor({
            index: sponsorIndex,
            fieldName: fieldName,
            subFieldName: subFieldName,
            data: value
        }));
    };
    const valueValidCallback = (status, key) => {
        // setTimeout(() => {
        formValid[ key ] = status;
        setFormValid(formValid);
        valueValid(checkValueValid());
        // }, 1);
    };

    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        // console.log(formValid);
        return true;
    };

    const typeOption = [
        {
            label: 'Lead',
            value: 'Lead'
        },
        {
            label: 'Platinum',
            value: 'Platinum'
        },
        {
            label: 'Gold',
            value: 'Gold'
        },
        {
            label: 'Sliver',
            value: 'Sliver'
        },
        {
            label: 'Bronze',
            value: 'Bronze'
        },
        {
            label: 'Basic',
            value: 'Basic'
        },
    ];

    const showDeleteModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
        deleteSponsor(sponsorIndex);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <div className="sponsors-container">
            <div className="step-session">
                <div className="session-title">
                    Sponsor
                </div>
                <div className="input-row grid-cols-1">
                    <FormTextField value={states[ 'data' ][ 'step5' ][ 'sponsors' ][ sponsorIndex ][ 'name' ]}
                                   isRequire={true}
                                   label={'Name'}
                                   valueValid={(v) => valueValidCallback(v, 'name')}
                                   valueChange={(v) => dataChange(v, 'name')}/>
                </div>

                <div className="input-row grid-cols-1">

                    <FormButtonOption
                        value={states[ 'data' ][ 'step5' ][ 'sponsors' ][ sponsorIndex ][ 'type' ]}
                        label={'Type'}
                        gridSize={'6'}
                        isRequire={true}
                        valueValid={(value) => valueValidCallback(value, 'type')}
                        valueChange={(value) => dataChange(value, 'type')}
                        option={typeOption}/>
                </div>
                <div className="input-row grid-cols-1">
                    <FormTextField
                        value={states[ 'data' ][ 'step5' ][ 'sponsors' ][ sponsorIndex ][ 'logoImageUrl' ]}
                        isRequire={true}
                        label={'Logo image URL'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">https://</InputAdornment>
                        }}
                        placeholder="sponsor_website.com/logo.jpg"
                        valueValid={(v) => valueValidCallback(v, 'logoImageUrl')}
                        valueChange={(v) => dataChange(v, 'logoImageUrl')}/>
                </div>
                <div className="input-row grid-cols-1">
                    <FormTextField
                        value={states[ 'data' ][ 'step5' ][ 'sponsors' ][ sponsorIndex ][ 'hyperlinkRedirectUrl' ]}
                        label={'Hyperlink redirect URL (optional)'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">https://</InputAdornment>
                        }}
                        placeholder="open_sponsor_website.com"
                        valueValid={(v) => valueValidCallback(v, 'hyperlinkRedirectUrl')}
                        valueChange={(v) => dataChange(v, 'hyperlinkRedirectUrl')}/>
                </div>

                <Modal title="Delete Sponsor" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                       okText={'Confirm'}>
                    <p>Confirm to delete this sponsor?</p>
                </Modal>
                {states[ 'data' ][ 'step5' ][ 'sponsors' ].length > 1 &&
                <div onClick={showDeleteModal}>
                    <IconButton icon={'delete'} text={'Delete this sponsor'}/>
                </div>
                }
            </div>
        </div>
    );
};
