import { useState } from 'react';
import * as React from 'react';
import './Step4.scss';
import { Session } from '../../../components/Session/Session';
import { GlobalFunctions } from '../../../GlobalFunctions';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStepData,
    update,
    updateEventPeriod,
    addEventPeriod,
    deleteEventPeriod, addChannel, deleteChannel, initHalls, initSessions
} from '../../../store/slice/stepData';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { Halls } from '../../../components/Halls/Halls';

export const Step4: React.FC<{}> = ({}) => {
    const gf = new GlobalFunctions();
    const [formValid, setFormValid] = useState({});
    const [init, setInit] = useState(false);
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    let history = useHistory();
    const valueValidCallback = (status, key) => {
        formValid[ key ] = status;
        setFormValid(formValid);
        dispatch(update({
            step: 4,
            fieldName: 'complete',
            data: checkValueValid()
        }));
    };
    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        return true;
    };

    const nextStep = () => {
        if (states[ 'data' ][ 'step4' ][ 'complete' ]) {
            gf.setLocalStorage('step4', states[ 'data' ][ 'step4' ]);
            notification[ 'success' ]({
                message: 'Processing complete'
            });
            history.push('/step/5');
        }
    };

    if (!init) {
        setInit(true);
        dispatch(initSessions({}));
    }
    return (
        <div className="step-page-content-wrapper">
            {states[ 'data' ][ 'step4' ][ 'dates' ].map((v, i) => {
                return (
                    <div key={i}>
                        <Session index={i}  valueValid={(v) => valueValidCallback(v, 'session' + i)}/>
                    </div>
                );
            })}

            <div className={`next-step ${states[ 'data' ][ 'step4' ][ 'complete' ] ? '' : 'disabled'}`}
                 onClick={nextStep}>
                Next Step
            </div>
        </div>
    );
};
