import { TextField } from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import format from 'date-fns/format';
import { useState } from 'react';
import * as React from 'react';
import { GlobalFunctions } from '../../GlobalFunctions';
import style from './FormDatePicker.module.scss';

export const FormDatePicker: React.FC<{
    value: string,
    isRequire?: boolean,
    label: string,
    className?: string,
    valueChange?: (value) => any,
    valueValid?: (value) => any,
}> = ({
          value,
          isRequire = false,
          label,
          className = '',
          valueChange = (value) => {
          },
          valueValid = (value) => {
          }
      }) => {
    const [showError, setShowError] = useState(false);
    const [valueValidState, setValueValidState] = useState(null);
    const gf = new GlobalFunctions();

    const dataChange = (event) => {
        setShowError(true);
        let isDate = false;
        if (Object.prototype.toString.call(event) === '[object Date]') {
            if (isNaN(event.getTime())) {
            } else {
                isDate = true;
            }
        }
        if (isDate) {
            valueChange(event ? format(event, 'MM/dd/yyyy') : '');
            checkValueValid();
        } else {
            if (!event) {
                valueChange('');
            } else {
                valueChange('Invalid Date');
            }
        }
    };
    const checkValueValid = () => {
        let status = !gf.fieldValidationError(value, isRequire, 'date').error;
        if (valueValidState !== status) {
            valueValid(status);
            setValueValidState(status);
        }
    };
    checkValueValid();
    return (
        <div className={`text-input ${className} ${style[ 'form-text-field' ]}`}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label={label}
                    value={value || null}
                    onChange={(newValue) => {
                        dataChange(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField className="input"
                                   error={showError && gf.fieldValidationError(value, isRequire, 'date').error}
                                   helperText={showError ? gf.errorMsg(gf.fieldValidationError(value, isRequire, 'date').messageCode) : ''}
                                   {...params} />}
                />
            </LocalizationProvider>
        </div>
    );
};
