import { Chip, InputAdornment, TextField } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import './Step8.scss';
import { Channels } from '../../../components/Channels/Channels';
import { FormButtonOption } from '../../../components/FormButtonOption/FormButtonOption';
import { FormDatePicker } from '../../../components/FormDatePicker/FormDatePicker';
import { FormTextField } from '../../../components/FormTextField/FormTextField';
import { IconButton } from '../../../components/IconButton/IconButton';
import { VideoScreenBanners } from '../../../components/VideoScreenBanners/VideoScreenBanners';
import { GlobalFunctions } from '../../../GlobalFunctions';
import style from './Step8.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    addVideoScreenBanners, deleteChannel, deleteVideoScreenBanners,
    selectStepData, update
} from '../../../store/slice/stepData';
import { Modal, notification } from 'antd';
import { Redirect, useHistory } from 'react-router-dom';
import image404 from '../../../assets/images/404.jpg';

export const Step8: React.FC<{}> = ({}) => {
    const gf = new GlobalFunctions();
    const [formValid, setFormValid] = useState({});
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    let history = useHistory();

    const dataChange = (value, fieldName, subFieldName?) => {
        dispatch(update({
            step: 8,
            fieldName: fieldName,
            subFieldName: subFieldName,
            data: value
        }));
    };

    const valueValidCallback = (status, key) => {
        formValid[ key ] = status;
        setFormValid(formValid);
        dispatch(update({
            step: 8,
            fieldName: 'complete',
            data: checkValueValid()
        }));
    };

    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        return true;
    };

    const themeOption = [
        {
            label: '<div class="design-theme-option purple"></div>',
            value: 'purple'
        },
        {
            label: '<div class="design-theme-option green"></div>',
            value: 'green'
        },
        {
            label: '<div class="design-theme-option orange"></div>',
            value: 'orange'
        }
    ];

    const nextStep = () => {
        if (states[ 'data' ][ 'step8' ][ 'complete' ]) {
            gf.setLocalStorage('step8', states[ 'data' ][ 'step8' ]);
            notification[ 'success' ]({
                message: 'Processing complete'
            });
            history.push('/step/9');
            window.scrollTo(0, 0);
        }
    };
    const imagePath = (image) => {
        if (image.substring(0, 8) != 'https://' && image.substring(0, 7) != 'http://') {
            image = 'https://' + image;
        }
        return image;
    };

    const addVideoScreenBannersClick = () => {
        dispatch(addVideoScreenBanners({}));
    };
    const deleteVideoScreenBannerCallback = (index, itemId) => {
        delete formValid[ 'videoScreenBanner' + itemId ];
        setFormValid(formValid);
        dispatch(deleteVideoScreenBanners({
            index: index
        }));
        dispatch(update({
            step: 8,
            fieldName: 'complete',
            data: checkValueValid()
        }));
    };
    return (
        <div className="step-page-content-wrapper">
            <div className="step-session">
                <div className="session-title">
                    Branding
                </div>
                {states[ 'data' ][ 'step8' ][ 'branding' ][ 'logoImageUrl' ] &&
                <div className={style[ 'branding-image' ]}>
                    <img src={imagePath(states[ 'data' ][ 'step8' ][ 'branding' ][ 'logoImageUrl' ])}
                         onError={(e) => {
                             // @ts-ignore
                             e.target.onerror = null;
                             // @ts-ignore
                             e.target.src = image404;
                         }}/>
                </div>
                }

                <div className="input-row grid-cols-1">
                    <FormTextField value={states[ 'data' ][ 'step8' ][ 'branding' ][ 'logoImageUrl' ]}
                                   isRequire={true}
                                   label={'Logo image URL'}
                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">https://</InputAdornment>
                                   }}
                                   placeholder="sponsor_website.com/logo.jpg"
                                   valueValid={(v) => valueValidCallback(v, 'brandingLogoImageUrl')}
                                   valueChange={(v) => dataChange(v, 'branding', 'logoImageUrl')}/>
                </div>
                <div className="input-row grid-cols-1">

                    <FormButtonOption
                        value={states[ 'data' ][ 'step8' ][ 'branding' ][ 'themeColor' ]}
                        label={'Theme color'}
                        gridSize={'3'}
                        isRequire={true}
                        valueValid={(value) => valueValidCallback(value, 'brandingthemeColor')}
                        valueChange={(value) => dataChange(value, 'branding', 'themeColor')}
                        option={themeOption}/>
                </div>

            </div>

            {states[ 'data' ][ 'step8' ][ 'videoScreenBanners' ].map((v, i) => {
                return (
                    <VideoScreenBanners videoScreenBannersIndex={i} key={i}
                                        valueValid={(value) => valueValidCallback(value, 'videoScreenBanner' + v[ 'itemId' ])}
                                        deleteVideoScreenBanner={(value) => deleteVideoScreenBannerCallback(value, v[ 'itemId' ])}/>
                );
            })}
            {(states[ 'data' ][ 'step8' ][ 'complete' ] || states[ 'data' ][ 'step8' ][ 'videoScreenBanners' ].length == 0) &&
            <div className="step-session add-item-control-btn" onClick={addVideoScreenBannersClick}>
                {states[ 'data' ][ 'step8' ][ 'videoScreenBanners' ].length == 0 && <div className="session-title">
                    Video screen banners
                </div>}

                <div className="icon">
                    <span className="webinar-icon-add"/>
                </div>
                <div className="text">Add banners</div>
            </div>
            }

            <div className={`next-step ${states[ 'data' ][ 'step8' ][ 'complete' ] ? '' : 'disabled'}`}
                 onClick={nextStep}>
                Next Step
            </div>
        </div>
    );
};
