import { TextField } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { GlobalFunctions } from '../../GlobalFunctions';
import style from './FormButtonOption.module.scss';

export const FormButtonOption: React.FC<{
        value: string | Array<string>,
        isRequire?: boolean,
        label: string,
        className?: string,
        gridSize?: string,
        multi?: boolean,
        valueChange?: (value) => any,
        valueValid?: (value) => any,
        option: Array<{
            label: string,
            subLabel?: string,
            value: string,
            disable?: boolean
        }>
    }> = ({
              value,
              isRequire = false,
              multi = false,
              label,
              className = '',
              valueChange = (value) => {
              },
              valueValid = (value) => {
              },
              option,
              gridSize = '4'
          }) => {
        const [showError, setShowError] = useState(false);
        const [valueValidState, setValueValidState] = useState(null);
        const gf = new GlobalFunctions();

        const dataChange = (event, disable) => {
            if (!disable || (disable && multi && value.indexOf(event) !== -1)) {
                setShowError(true);
                let thisValue: any;
                if (multi) {
                    thisValue = JSON.stringify(value);
                    thisValue = JSON.parse(thisValue);
                    if (thisValue.indexOf(event) === -1) {
                        thisValue.push(event);
                    } else {
                        thisValue.splice(thisValue.indexOf(event), 1);
                    }
                } else {
                    thisValue = event;
                }
                valueChange(thisValue);
                checkValueValid();
            }
        };

        const checkValueValid = () => {
            let status = !gf.fieldValidationError(value, isRequire).error;
            if (valueValidState !== status) {
                valueValid(status);
                setValueValidState(status);
            }
        };
        checkValueValid();

        const checkSelected = (thisCheckValue) => {
            let selected = false;
            if (multi) {
                let thisValue: any = JSON.stringify(value);
                thisValue = JSON.parse(thisValue);
                if (thisValue.indexOf(thisCheckValue) !== -1) {
                    selected = true;
                }
            } else {
                if (thisCheckValue == value) {
                    selected = true;
                }
            }
            return selected;
        };

        return (
            <div className={`text-input ${className} ${style[ 'form-button-option' ]}`}>
                <div className={style.label}>
                    {label}
                </div>
                <div className={`grid-cols-${gridSize} ${style.options}`}>
                    {option.map((v, i) => {
                        return (
                            <div
                                key={i}
                                className={`${style.option}  ${checkSelected(v.value) ? style.selected : ''} ${v.disable ? style.disabled : ''} `}
                                onClick={() => dataChange(v.value, v.disable)}>
                                <div className={style[ 'option-content' ]}>
                                    <div className={style[ 'option-label' ]} dangerouslySetInnerHTML={{ __html: v.label }}></div>
                                    {v.subLabel && <div className={style[ 'option-sub-label' ]}
                                                        dangerouslySetInnerHTML={{ __html: v.subLabel }}/>}
                                </div>

                            </div>
                        );
                    })}
                </div>

                {/*<TextField className="input" label={label} variant="outlined"*/}
                {/*           value={value}*/}
                {/*           multiline={multiline}*/}
                {/*           rows={multilineRows}*/}
                {/*           placeholder={placeholder}*/}
                {/*           InputProps={InputProps}*/}
                {/*           error={showError && gf.fieldValidationError(value, isRequire, type).error}*/}
                {/*           helperText={showError ? gf.errorMsg(gf.fieldValidationError(value, isRequire, type).messageCode) : ''}*/}
                {/*           onChange={(e) => dataChange(e)}/>*/}
            </div>
        );
    }
;
