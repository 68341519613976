import { InputAdornment, TextField } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import './Step7.scss';
import { FileDrop } from 'react-file-drop';
import { FormButtonOption } from '../../../components/FormButtonOption/FormButtonOption';
import style from '../../../components/FormButtonOption/FormButtonOption.module.scss';
import { FormDatePicker } from '../../../components/FormDatePicker/FormDatePicker';
import { FormTextField } from '../../../components/FormTextField/FormTextField';
import { IconButton } from '../../../components/IconButton/IconButton';
import { Sponsor } from '../../../components/Sponsor/Sponsor';
import { GlobalFunctions } from '../../../GlobalFunctions';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStepData,
    update, deleteSponsor, addSponsor, updateAudience, cleanAudience, addAudience, addAudienceByArray, deleteAudience
} from '../../../store/slice/stepData';
import { Modal, notification } from 'antd';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import template from './../../../assets/doc/audience.csv';

export const Step7: React.FC<{}> = ({}) => {
    const gf = new GlobalFunctions();
    const [formValid, setFormValid] = useState({});
    const [formAudienceValid, setFormAudienceValid] = useState({});
    const [canSave, setCanSave] = useState(false);
    const [addAudienceModelStatus, setAddAudienceModelStatus] = useState(false);
    const [importAudienceModelStatus, setImportAudienceModelStatus] = useState(false);
    const [reload, setReload] = useState(false);
    const [selectedAudience, setSelectedAudience] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    let history = useHistory();
    const audienceChange = (value, field) => {
        dispatch(updateAudience({
            fieldName: field,
            data: value
        }));
    };

    const valueValidAudienceCallback = (status, key) => {
        formAudienceValid[ key ] = status;
        setFormAudienceValid(formAudienceValid);
        setCanSave(checkFormAudienceValidValid());
    };

    const checkFormAudienceValidValid = () => {
        for (let key in formAudienceValid) {
            if (!formAudienceValid[ key ]) {
                return false;
            }
        }
        return true;
    };
    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        return true;
    };

    const nextStep = () => {
        if (states[ 'data' ][ 'step7' ][ 'complete' ]) {
            gf.setLocalStorage('step7', states[ 'data' ][ 'step7' ]);
            notification[ 'success' ]({
                message: 'Processing complete'
            });
            history.push('/step/8');
            window.scrollTo(0, 0);
        }
    };
    const showAddAudienceModal = () => {
        dispatch(cleanAudience({}));
        setAddAudienceModelStatus(true);
    };
    const showImportAudienceModal = () => {
        setImportAudienceModelStatus(true);
    };

    const roleOption = [
        {
            label: 'Host',
            subLabel: 'Super Control',
            value: 'host'
        },
        {
            label: 'Co-host',
            subLabel: 'Access Q&A <br>(projection screen)<br>in all sessions',
            value: 'co-host'
        },
        {
            label: 'Panelist',
            subLabel: 'Access Q&A <br>(projection screen)<br>in current sessions',
            value: 'panelist'
        }
    ];
    const addAudienceHandler = () => {
        if (canSave) {
            dispatch(addAudience({}));
            setAddAudienceModelStatus(false);
        }
    };

    const dropFile = (files, event) => {
        if (files[ 0 ][ 'type' ] === 'text/csv') {
            var reader = new FileReader();
            reader.onload = function (e) {
                // Use reader.result
                console.log(JSON.stringify(reader.result));
                let csv = reader.result;
                var lines = csv.toString().split('\r\n');
                var result = [];
                var headers = lines[ 0 ].split(',');
                for (var i = 1; i < lines.length; i++) {
                    var obj = {};
                    var currentline = lines[ i ].split(',');

                    for (var j = 0; j < headers.length; j++) {
                        obj[ headers[ j ] ] = currentline[ j ];
                    }
                    result.push(obj);
                }
                dispatch(addAudienceByArray({
                    data: result
                }));
                setImportAudienceModelStatus(false);

                notification[ 'success' ]({
                    message: 'Import success'
                });
            };
            reader.readAsText(files[ 0 ]);
        } else {
            notification[ 'error' ]({
                message: 'Invalid file'
            });
        }
    };
    const toggleAudience = (index) => {
        if (index == 'all') {
            let check = checkAudienceNotSelect();
            states[ 'data' ][ 'step7' ][ 'audience' ].forEach((v, i) => {
                selectedAudience[ i ] = !check;
            });
        } else {
            let status = false;
            if (!selectedAudience[ index ]) {
                status = true;
            }
            selectedAudience[ index ] = status;
        }
        setSelectedAudience(selectedAudience);
        setReload(!reload);
    };

    const checkAudienceHasSelect = () => {
        let check = false;
        states[ 'data' ][ 'step7' ][ 'audience' ].forEach((v, i) => {
            if (selectedAudience[ i ]) {
                check = true;
            }
        });
        return check;
    };

    const checkAudienceNotSelect = () => {
        let check = true;
        states[ 'data' ][ 'step7' ][ 'audience' ].forEach((v, i) => {
            if (!selectedAudience[ i ]) {
                check = false;
            }
        });
        return check;
    };
    const handleOk = () => {
        let itemsIds = [];
        for (let key in selectedAudience) {
            if (selectedAudience[ key ]) {
                itemsIds.push(states[ 'data' ][ 'step7' ][ 'audience' ][ key ][ 'itemId' ]);
            }
        }
        dispatch(deleteAudience({
            ids: itemsIds
        }));
        setIsModalVisible(false);
        setSelectedAudience({});
        setReload(!reload);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="step-page-content-wrapper">
            {importAudienceModelStatus &&
            <div className="import-audience-model">
                <div className="mask" onClick={() => setImportAudienceModelStatus(false)}/>
                <div className="modal-container">
                    <div className="modal-title flex items-center">
                        <div className="text">Import from CSV</div>
                        <div className="template-btn ml-auto">
                            <a href={template}>
                                <IconButton icon={'download'} text={'Download a CSV sample'}/>
                            </a>
                        </div>
                    </div>
                    <div className="file-drop-zone">
                        <FileDrop
                            onDrop={dropFile}
                        >
                            <div className="text">
                                Drop and drop a CSV file here or click
                            </div>
                            <div className="icon">
                                <span className="webinar-icon-upload"/>
                            </div>
                        </FileDrop>
                    </div>

                </div>
            </div>
            }

            {addAudienceModelStatus &&
            <div className="add-audience-model">
                <div className="mask" onClick={() => setAddAudienceModelStatus(false)}/>
                <div className="modal-container">
                    <div className="session-title">
                        Add an audience
                        <div className="close-btn" onClick={() => setAddAudienceModelStatus(false)}>
                            <span className="webinar-icon-close"></span>
                        </div>
                    </div>

                    <div className="input-row grid-cols-1">
                        <FormTextField value={states[ 'data' ][ 'audience' ][ 'name' ]}
                                       isRequire={true}
                                       label={'Display Name'}
                                       valueValid={(v) => valueValidAudienceCallback(v, 'name')}
                                       valueChange={(v) => audienceChange(v, 'name')}/>
                    </div>

                    <div className="input-label">
                        Sign-in
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField value={states[ 'data' ][ 'audience' ][ 'email' ]}
                                       isRequire={true}
                                       label={'E-mail address'}
                                       type={'email'}
                                       valueValid={(v) => valueValidAudienceCallback(v, 'email')}
                                       valueChange={(v) => audienceChange(v, 'email')}/>
                    </div>
                    <div className="input-row grid-cols-2">
                        <FormTextField value={states[ 'data' ][ 'audience' ][ 'tel' ]}
                                       isRequire={true}
                                       label={'Telephone number'}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">+852.</InputAdornment>
                                       }}
                                       valueValid={(v) => valueValidAudienceCallback(v, 'tel')}
                                       valueChange={(v) => audienceChange(v, 'tel')}/>
                        <FormTextField value={states[ 'data' ][ 'audience' ][ 'verifyCode' ]}
                                       isRequire={true}
                                       label={'Verify code'}
                                       valueValid={(v) => valueValidAudienceCallback(v, 'verifyCode')}
                                       valueChange={(v) => audienceChange(v, 'verifyCode')}/>
                    </div>

                    <div className={`save-btn ${canSave ? '' : 'disabled'}`} onClick={addAudienceHandler}>
                        Save
                    </div>
                </div>
            </div>
            }

            <Modal title="Delete Audience" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                   okText={'Confirm'}>
                <p>Confirm to delete these audience?</p>
            </Modal>
            <div className="step-session">
                <div className="session-title">
                    Audience
                </div>
                <div className={'table-container'}>
                    {states[ 'data' ][ 'step7' ][ 'audience' ].length == 0 && <div className="no-data">
                        No audience found
                    </div>}
                    {states[ 'data' ][ 'step7' ][ 'audience' ].length > 0 &&
                    <div className="data-container">
                        {!checkAudienceHasSelect() && <div className="control-header">
                            <div className="search-bar">
                                <TextField className="input" InputProps={{
                                    startAdornment: <InputAdornment position="start"><span
                                        className="webinar-icon-search"/></InputAdornment>
                                }} placeholder={'Search by name of e-mail or tel-number'} label={'Search'}
                                           variant="outlined"/>
                            </div>
                            <div className="export-btn">Export</div>
                        </div>}
                        {checkAudienceHasSelect() &&
                        <div className="action-btns">
                            <div className="action-btn" onClick={() => setIsModalVisible(true)}>
                                <IconButton icon={'delete'} text={'Delete these audience'}/>
                            </div>
                            <div className="action-btn">
                                <IconButton icon={'send'} text={'Send verify code'}/>
                            </div>
                        </div>
                        }
                        <div className="table-wrapper audience-table">
                            <table>
                                <tr>
                                    <th>
                                        <div className="checkbox" onClick={() => toggleAudience('all')}></div>
                                    </th>
                                    <th>Select</th>
                                    <th>Name</th>
                                    <th>E-mail/ Tel-number</th>
                                    <th>Verify code</th>
                                    <th>Last login</th>
                                </tr>
                                {states[ 'data' ][ 'step7' ][ 'audience' ].map((v, i) => {
                                    return (
                                        <tr>
                                            <td valign="top">
                                                <div className={`checkbox ${selectedAudience[ i ] ? 'active' : ''}`}
                                                     onClick={() => toggleAudience(i)}/>
                                            </td>
                                            <td valign="top">
                                                <div className="status-ball"></div>
                                            </td>
                                            <td valign="top">
                                                <div className="name">{v[ 'name' ]}</div>
                                            </td>
                                            <td valign="top">
                                                <div className="email">{v[ 'email' ]}</div>
                                                <div className="tel">{v[ 'tel' ]}</div>
                                            </td>
                                            <td valign="top">
                                                <div className="verify-code">
                                                    {v[ 'verifyCode' ]}
                                                </div>
                                            </td>
                                            <td valign="top">
                                                <div className="last-login-time">
                                                    10 May 2021
                                                </div>
                                                <div className="last-login-by">
                                                    by E-mail
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </div>
                    </div>
                    }
                </div>

            </div>

            <div className="grid grid-flow-col gap-12 grid-cols-2">
                <div className="step-session add-item-control-btn " onClick={() => showAddAudienceModal()}>
                    <div className="icon">
                        <span className="webinar-icon-add"></span>
                    </div>
                    <div className="text">Add a audience</div>
                </div>
                <div className="step-session add-item-control-btn " onClick={() => showImportAudienceModal()}>
                    <div className="icon">
                        <span className="webinar-icon-list"></span>
                    </div>
                    <div className="text">Import audience from CSV</div>
                </div>
            </div>

            <div className={`next-step ${states[ 'data' ][ 'step7' ][ 'complete' ] ? '' : 'disabled'}`}
                 onClick={nextStep}>
                Next Step
            </div>
        </div>
    );
};
