import { Icon, InputAdornment } from '@material-ui/core';
import { Modal, Steps } from 'antd';
import { useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GlobalFunctions } from '../../GlobalFunctions';
import {
    addSession,
    deleteSession,
    selectStepData,
    updateSession,
    addPanelist,
    deletePanelist
} from '../../store/slice/stepData';
import './Session.scss';
import { FormButtonOption } from '../FormButtonOption/FormButtonOption';
import { FormTextField } from '../FormTextField/FormTextField';
import { FormTimePicker } from '../FormTimePicker/FormTimePicker';
import { IconButton } from '../IconButton/IconButton';
import { Panelists } from '../Panelists/Panelists';

export const Session: React.FC<{
    index: number,
    valueValid?: (value) => any
}> = ({
          index,
          valueValid = (value) => {
          }
      }) => {
    const gf = new GlobalFunctions();
    const states = useSelector(selectStepData);
    const [formValid, setFormValid] = useState({});
    const [deleteSessionIndex, setDeleteSessionIndex] = useState(0);
    const [deleteSessionItemId, setDeleteSessionItemId] = useState('');
    const [canAdd, setCanAdd] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPanelistModalVisible, setIsPanelistModalVisible] = useState(false);
    const [deletePanelistSessionIndex, setDeletePanelistSessionIndex] = useState(0);
    const [deletePanelistItemId, setDeletePanelistItemId] = useState('');
    const [deletePanelistIndex, setDeletePanelistIndex] = useState('');
    const sessionData = states[ 'data' ][ 'step4' ][ 'dates' ][ index ];
    const dispatch = useDispatch();

    console.log(sessionData);

    const dataChange = (sessionIndex, value, fieldName, subFieldName?) => {
        dispatch(updateSession({
            dateIndex: index,
            sessionIndex: sessionIndex,
            fieldName: fieldName,
            subFieldName: subFieldName,
            data: value
        }));
    };
    const valueValidCallback = (status, key) => {
        formValid[ key ] = status;
        setFormValid(formValid);
        valueValid(checkValueValid());
        setCanAdd(checkValueValid());
    };

    let occupiedHallsOption = [];

    states[ 'data' ][ 'step3' ][ 'dates' ][ index ][ 'halls' ].forEach((v) => {
        occupiedHallsOption.push({
            label: v.hallName,
            value: v.hallName
        });
    });

    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        return true;
    };

    const addSessionHandler = () => {
        dispatch(addSession({
            dateIndex: index
        }));
    };

    const addPanelistHandler = (sessionIndex) => {
        dispatch(addPanelist({
            dateIndex: index,
            sessionIndex: sessionIndex
        }));
    };
    const showDeleteSessionModel = (i, itemId) => {
        setIsModalVisible(true);
        setDeleteSessionIndex(i);
        setDeleteSessionItemId(itemId);
    };
    const handleOk = () => {
        setIsModalVisible(false);
        dispatch(deleteSession({
            dateIndex: index,
            sessionIndex: deleteSessionIndex
        }));
        delete formValid[ 'sessionName' + deleteSessionItemId ];
        delete formValid[ 'channel' + deleteSessionItemId ];
        setFormValid(formValid);
        valueValid(checkValueValid());
        setCanAdd(checkValueValid());
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showDeletePanelistModel = (i, panelistIndex, itemId) => {
        setIsPanelistModalVisible(true);
        setDeletePanelistSessionIndex(i);
        setDeletePanelistIndex(panelistIndex);
        setDeletePanelistItemId(itemId);
    };
    const handlePanelistOk = () => {
        setIsPanelistModalVisible(false);
        dispatch(deletePanelist({
            dateIndex: index,
            sessionIndex: deletePanelistSessionIndex,
            panelistIndex: deletePanelistIndex
        }));
        delete formValid[ 'Panelists' + deletePanelistItemId ];
        setFormValid(formValid);
        valueValid(checkValueValid());
        setCanAdd(checkValueValid());
    };

    const handlePanelistCancel = () => {
        setIsPanelistModalVisible(false);
    };

    return (
        <div className="sessions-container">

            {sessionData[ 'sessions' ].map((v, i) => {
                return (
                    <div className="step-session" key={i}>
                        <div className="session-title">
                            Session on {sessionData[ 'date' ]}
                        </div>
                        <div className="input-row grid-cols-1">
                            <FormTextField
                                value={sessionData[ 'sessions' ][ i ][ 'sessionName' ]}
                                isRequire={true}
                                label={'Session name'}
                                valueValid={(value) => valueValidCallback(value, 'sessionName' + v[ 'itemId' ])}
                                valueChange={(value) => dataChange(i, value, 'sessionName')}/>
                        </div>
                        <div className="input-row grid-cols-12 no-margin-bottom big-gap">
                            <div className="input-row grid-cols-2 col-span-7">
                                <FormTimePicker
                                    value={sessionData[ 'sessions' ][ i ][ 'startTime' ]}
                                    isRequire={true}
                                    label={'Start Time'}
                                    valueValid={(value) => valueValidCallback(value, 'startTime' + v[ 'itemId' ])}
                                    valueChange={(value) => dataChange(i, value, 'startTime')}/>
                                <FormTimePicker
                                    value={sessionData[ 'sessions' ][ i ][ 'endTime' ]}
                                    isRequire={true}
                                    label={'End Time'}
                                    valueValid={(value) => valueValidCallback(value, 'endTime' + v[ 'itemId' ])}
                                    valueChange={(value) => dataChange(i, value, 'endTime')}/>
                            </div>
                            <div className="input-row grid-cols-1 col-span-5">
                                <FormTextField
                                    value={sessionData[ 'sessions' ][ i ][ 'orderingNumber' ]}
                                    isRequire={true}
                                    typeAttr={'number'}
                                    label={'Ordering number (optional)'}
                                    valueValid={(value) => valueValidCallback(value, 'orderingNumber' + v[ 'itemId' ])}
                                    valueChange={(value) => dataChange(i, value, 'orderingNumber')}/>
                            </div>
                        </div>
                        <div className="input-row grid-cols-12 no-margin-bottom big-gap">
                            <div className="input-row grid-cols-1 col-span-7">
                                <FormButtonOption
                                    value={sessionData[ 'sessions' ][ i ][ 'type' ]}
                                    label={'Type'}
                                    gridSize={'2'}
                                    isRequire={true}
                                    valueValid={(value) => valueValidCallback(value, 'type' + v[ 'itemId' ])}
                                    valueChange={(value) => dataChange(i, value, 'type')}
                                    option={[
                                        {
                                            label: 'On-show',
                                            value: 'On-show'
                                        },
                                        {
                                            label: 'Break time',
                                            value: 'Break time'
                                        }
                                    ]}/>
                            </div>
                            <div className="input-row grid-cols-1 col-span-5">
                                <FormButtonOption
                                    value={sessionData[ 'sessions' ][ i ][ 'subSession' ]}
                                    label={'Sub-session'}
                                    gridSize={'2'}
                                    isRequire={true}
                                    valueValid={(value) => valueValidCallback(value, 'subSession' + v[ 'itemId' ])}
                                    valueChange={(value) => dataChange(i, value, 'subSession')}
                                    option={[
                                        {
                                            label: 'No',
                                            value: 'No'
                                        },
                                        {
                                            label: 'Yes',
                                            value: 'Yes'
                                        }
                                    ]}/>
                            </div>
                        </div>

                        <div className="input-row grid-cols-1 col-span-5">
                            <FormButtonOption
                                value={sessionData[ 'sessions' ][ i ][ 'occupiedHalls' ]}
                                label={'Occupied halls'}
                                gridSize={'3'}
                                isRequire={true}
                                valueValid={(value) => valueValidCallback(value, 'occupiedHalls' + v[ 'itemId' ])}
                                valueChange={(value) => dataChange(i, value, 'occupiedHalls')}
                                option={occupiedHallsOption}/>
                        </div>

                        <div className="panelist-label">
                            Panelists <span className="sub">(optional)</span>
                        </div>
                        {sessionData[ 'sessions' ][ i ][ 'Panelists' ].map((thisPanelists, panelistIndex) => {
                            return (
                                <div className="input-row grid-cols-12 col-span-5 no-margin-bottom">
                                    <div className="col-span-7">
                                        <Panelists sessionsIndex={i} index={index} panelistIndex={panelistIndex}
                                                   valueValid={(value) => valueValidCallback(value, 'Panelists' + v[ 'itemId' ])}/>
                                    </div>
                                    <div className="col-span-5">
                                        <div className="flex mt-3">
                                            <div
                                                onClick={() => showDeletePanelistModel(i, panelistIndex, v[ 'itemId' ])}>
                                                <IconButton icon={'delete'} text={'Delete'}/>
                                            </div>
                                            {panelistIndex == 0 &&
                                            <div className="ml-4" onClick={() => addPanelistHandler(i)}>
                                                <IconButton icon={'add'} text={'Add panelist'}/>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {sessionData[ 'sessions' ].length > 1 &&
                        <div onClick={() => showDeleteSessionModel(i, v[ 'itemId' ])}>
                            <IconButton icon={'delete'} text={'Delete this session'}/>
                        </div>
                        }
                    </div>
                );
            })}

            <Modal title="Delete Panelist" visible={isPanelistModalVisible} onOk={handlePanelistOk}
                   onCancel={handlePanelistCancel}
                   okText={'Confirm'}>
                <p>Confirm to delete this session?</p>
            </Modal>
            <Modal title="Delete Session" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                   okText={'Confirm'}>
                <p>Confirm to delete this session?</p>
            </Modal>
            {canAdd &&
            <div className="step-session add-item-control-btn" onClick={addSessionHandler}>
                <div className="icon">
                    <span className="webinar-icon-add"></span>
                </div>
                <div className="text">Add a session on {sessionData[ 'date' ]}</div>
            </div>
            }
        </div>
    );
};
