import { TextField } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { GlobalFunctions } from '../../GlobalFunctions';
import style from './FormTextField.module.scss';

export const FormTextField: React.FC<{
    value: string,
    isRequire?: boolean,
    label: string,
    placeholder?: string,
    className?: string,
    type?: string,
    typeAttr?: string,
    multiline?: boolean,
    multilineRows?: number,
    valueChange?: (value) => any,
    InputProps?: {},
    valueValid?: (value) => any,
}> = ({
          value,
          isRequire = false,
          multiline = false,
          label,
          className = '',
          placeholder = '',
          type = '',
                                      typeAttr = 'text',
          multilineRows = 1,
          valueChange = (value) => {
          },
          valueValid = (value) => {
          },
          InputProps = {}
      }) => {
    const [showError, setShowError] = useState(false);
    const [valueValidState, setValueValidState] = useState(null);
    const gf = new GlobalFunctions();

    const dataChange = (event) => {
        setShowError(true);
        valueChange(event.target.value);
        checkValueValid();
    };

    const checkValueValid = () => {
        let status = !gf.fieldValidationError(value, isRequire, type).error;
        if (valueValidState !== status) {
            valueValid(status);
            setValueValidState(status);
        }
    };
    checkValueValid();
    return (
        <div className={`text-input ${className} ${style[ 'form-text-field' ]}`}>
            <TextField className="input" label={label} variant="outlined"
                       value={value}
                       multiline={multiline}
                       rows={multilineRows}
                       placeholder={placeholder}
                       InputProps={InputProps}
                       type={typeAttr}
                       error={showError && gf.fieldValidationError(value, isRequire, type).error}
                       helperText={showError ? gf.errorMsg(gf.fieldValidationError(value, isRequire, type).messageCode) : ''}
                       onChange={(e) => dataChange(e)}/>
        </div>
    );
};
