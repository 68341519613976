import { useState } from 'react';
import * as React from 'react';
import './Step3.scss';
import { Channels } from '../../../components/Channels/Channels';
import { FormDatePicker } from '../../../components/FormDatePicker/FormDatePicker';
import { IconButton } from '../../../components/IconButton/IconButton';
import { GlobalFunctions } from '../../../GlobalFunctions';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStepData,
    update,
    updateEventPeriod,
    addEventPeriod,
    deleteEventPeriod, addChannel, deleteChannel, initHalls
} from '../../../store/slice/stepData';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { Halls } from '../../../components/Halls/Halls';

export const Step3: React.FC<{}> = ({}) => {
    const gf = new GlobalFunctions();
    const [formValid, setFormValid] = useState({});
    const [init, setInit] = useState(false);
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    let history = useHistory();

    const valueValidCallback = (status, key) => {
        // setTimeout(() => {
        formValid[ key ] = status;
        setFormValid(formValid);
        dispatch(update({
            step: 3,
            fieldName: 'complete',
            data: checkValueValid()
        }));
        // }, 1);
    };
    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        return true;
    };

    const nextStep = () => {
        if (states[ 'data' ][ 'step3' ][ 'complete' ]) {
            gf.setLocalStorage('step3', states[ 'data' ][ 'step3' ]);
            notification[ 'success' ]({
                message: 'Processing complete'
            });
            history.push('/step/4');
        }
    };

    if (!init) {
        setInit(true);
        dispatch(initHalls({}));
    }
    return (
        <div className="step-page-content-wrapper">
            {states[ 'data' ][ 'step3' ][ 'dates' ].map((v, i) => {
                return (
                    <div key={i}>
                        <Halls index={i}  valueValid={(v) => valueValidCallback(v, 'hall' + i)}/>
                    </div>
                );
            })}

            <div className={`next-step ${states[ 'data' ][ 'step3' ][ 'complete' ] ? '' : 'disabled'}`}
                 onClick={nextStep}>
                Next Step
            </div>
        </div>
    );
};
