import { Component } from 'react';
import * as React from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import './header.scss';
import { selectStepData } from '../../store/slice/stepData';
import { selectShareData } from '../../store/slice/shareData';
import logo from './../../assets/images/header/mysellpay-logo.svg';

export const Header: React.FC<{}> = ({}) => {
    const stepStates = useSelector(selectStepData);
    return (
        <div>
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-container">
                        <div className="logo">
                            <img src={logo}/>
                        </div>
                        <div className="site-name">
                            {!stepStates[ 'data' ][ 'step1' ][ 'eventName' ] && 'Webinar Service'}
                            {stepStates[ 'data' ][ 'step1' ][ 'eventName' ] && stepStates[ 'data' ][ 'step1' ][ 'eventName' ]}
                        </div>
                        <div className="time">
                            {stepStates[ 'data' ][ 'lastEdited' ]}
                        </div>
                        <div className="action-btn btn-1">
                            <span className="webinar-icon-eye"></span>
                        </div>
                        <div className="action-btn btn-2">
                            <span className="webinar-icon-lock"></span>
                        </div>
                        <div className="action-btn btn-3">
                            <span className="webinar-icon-circle"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-padding"></div>
        </div>
    );
};
