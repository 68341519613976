import { InputAdornment } from '@material-ui/core';
import { Modal, Steps } from 'antd';
import { useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import image404 from '../../assets/images/404.jpg';
import style from '../../pages/StepPage/Step8/Step8.module.scss';
import {
    addChannel,
    addVideoScreenBanners,
    selectStepData,
    updateVideoScreenBanners
} from '../../store/slice/stepData';
import './VideoScreenBanners.scss';
import { FormButtonOption } from '../FormButtonOption/FormButtonOption';
import { FormSelectField } from '../FormSelectField/FormSelectField';
import { FormTextField } from '../FormTextField/FormTextField';
import { FormToggle } from '../FormToggle/FormToggle';
import { IconButton } from '../IconButton/IconButton';

export const VideoScreenBanners: React.FC<{
    videoScreenBannersIndex: number,
    valueValid?: (value) => any
    deleteVideoScreenBanner?: (value) => any
}> = ({
          videoScreenBannersIndex,
          valueValid = (value) => {
          },
          deleteVideoScreenBanner = (value) => {
          }
      }) => {
    const [formValid, setFormValid] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();

    const dataChange = (value, fieldName, subFieldName?) => {
        dispatch(updateVideoScreenBanners({
            index: videoScreenBannersIndex,
            fieldName: fieldName,
            subFieldName: subFieldName,
            data: value
        }));
    };
    const valueValidCallback = (status, key) => {
        // setTimeout(() => {
        formValid[ key ] = status;
        setFormValid(formValid);
        valueValid(checkValueValid());
        // }, 1);
    };

    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        // console.log(formValid);
        return true;
    };

    const backupOption = [
        {
            label: 'No backup channel',
            value: 'No backup channel'
        },
        {
            label: 'Setup a backup channel',
            value: 'Setup a backup channel'
        }
    ];

    const showDeleteModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
        deleteVideoScreenBanner(videoScreenBannersIndex);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const positionOption = [
        {
            label: 'Top Banner',
            subLabel: '<div class="design-theme-option purple"></div>',
            value: 'top'
        },
        {
            label: 'Middle',
            subLabel: '<div class="design-theme-option green"></div>',
            value: 'middle'
        },
        {
            label: 'Bottom',
            subLabel: '<div class="design-theme-option orange"></div>',
            value: 'bottom'
        }
    ];
    const dateOption = [
        {
            label: 'Apply to all event dates',
            value: 'all'
        }
    ];


    states[ 'data' ][ 'step1' ][ 'eventPeriod' ].forEach((v) => {
        dateOption.push({
            label: 'Event date: ' + v.date,
            value: v.date
        });
    });
    const imagePath = (image) => {
        if (image.substring(0, 8) != 'https://' && image.substring(0, 7) != 'http://') {
            image = 'https://' + image;
        }
        return image;
    };
    return (
        <div className="videoScreenBanners-container">
            <div className="step-session">
                <div className="session-title">
                    VideoScreenBanner
                </div>
                <div className="input-row grid-cols-1">
                    <FormButtonOption
                        value={states[ 'data' ][ 'step8' ][ 'videoScreenBanners' ][ videoScreenBannersIndex ][ 'position' ]}
                        label={''}
                        gridSize={'3'}
                        isRequire={true}
                        valueValid={(v) => valueValidCallback(v, 'position')}
                        valueChange={(v) => dataChange(v, 'position')}
                        option={positionOption}/>
                </div>

                {states[ 'data' ][ 'step8' ][ 'videoScreenBanners' ][ videoScreenBannersIndex ][ 'imageUrl' ] &&
                <div className="video-screen-banner-image-box">
                    <img
                        src={imagePath(states[ 'data' ][ 'step8' ][ 'videoScreenBanners' ][ videoScreenBannersIndex ][ 'imageUrl' ])}
                        onError={(e) => {
                            // @ts-ignore
                            e.target.onerror = null;
                            // @ts-ignore
                            e.target.src = image404;
                        }}/>
                </div>
                }

                <div className="input-row grid-cols-1">
                    <FormTextField
                        value={states[ 'data' ][ 'step8' ][ 'videoScreenBanners' ][ videoScreenBannersIndex ][ 'imageUrl' ]}
                        isRequire={true}
                        label={'Image URL'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">https://</InputAdornment>
                        }}
                        placeholder="sponsor_website.com/logo.jpg"
                        valueValid={(v) => valueValidCallback(v, 'imageUrl')}
                        valueChange={(v) => dataChange(v, 'imageUrl')}/>
                </div>
                <div className="input-row grid-cols-1">
                    <FormTextField
                        value={states[ 'data' ][ 'step8' ][ 'videoScreenBanners' ][ videoScreenBannersIndex ][ 'hyperlinkRedirectUrl' ]}
                        isRequire={false}
                        label={'Hyperlink redirect URL (optional)'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">https://</InputAdornment>
                        }}
                        placeholder="open_sponsor_website.com"
                        valueValid={(v) => valueValidCallback(v, 'hyperlinkRedirectUrl')}
                        valueChange={(v) => dataChange(v, 'hyperlinkRedirectUrl')}/>
                </div>
                <div className="input-row grid-cols-1">
                    <FormSelectField
                        value={states[ 'data' ][ 'step8' ][ 'videoScreenBanners' ][ videoScreenBannersIndex ][ 'date' ]}
                        isRequire={true}
                        option={dateOption}
                        label={'Date specific'}
                        valueValid={(v) => valueValidCallback(v, 'date')}
                        valueChange={(v) => dataChange(v, 'date')}/>
                </div>
                <Modal title="Delete Video Screen Banner" visible={isModalVisible} onOk={handleOk}
                       onCancel={handleCancel}
                       okText={'Confirm'}>
                    <p>Confirm to delete this video screen banner?</p>
                </Modal>
                <div onClick={showDeleteModal}>
                    <IconButton icon={'delete'} text={'Delete these video screen banner'}/>
                </div>
            </div>
        </div>
    );
};
