import { createSlice } from '@reduxjs/toolkit';
import { GlobalFunctions } from '../../GlobalFunctions';

export const stepDataSlice = createSlice({
    name: 'stepData',
    initialState: {
        data: {
            step1: {
                eventName: '',
                eventTagline: '',
                customUrl: '',
                defaultPermalink: 'wbs_e717cb0e3c4c74faadea565d7b62dc551a749f96f11233cf0d2..',
                eventPeriod: [
                    {
                        itemId: 'SDG42',
                        date: ''
                    }
                ],
                generalEnquiry: {
                    contactPerson: '',
                    email: '',
                    phone: ''
                },
                technicalEnquiry: {
                    contactPerson: '',
                    email: '',
                    phone: ''
                },
                registrationEnquiry: {
                    contactPerson: '',
                    email: '',
                    phone: ''
                },
                description: '',
                complete: false
            },
            step2: {
                channel: [
                    {
                        itemId: 'sd01r',
                        channelName: '',
                        liveStreamingProvider: '',
                        streamIngestDomain: '',
                        broadcastPlaybackDomain: '',
                        streamPrimaryAKey: '',
                        streamIngestUrl: '',
                        broadcastPlaybackUrl: '',
                        broadcastPlaybackUrlStatus: '',
                        streamKey: '',
                        backupChannelOption: '',
                        backupChannel: {
                            liveStreamingProvider: '',
                            streamIngestDomain: '',
                            broadcastPlaybackDomain: '',
                            streamPrimaryAKey: '',
                            streamIngestUrl: '',
                            broadcastPlaybackUrl: '',
                            broadcastPlaybackUrlStatus: '',
                            streamKey: ''
                        }
                    }
                ],
                complete: false
            },
            step3: {
                dates: [],
                complete: false
            },
            step4: {
                dates: [],
                complete: false
            },
            step5: {
                sponsors: [
                    {
                        itemId: 'sd01r',
                        name: '',
                        type: '',
                        logoImageUrl: '',
                        hyperlinkRedirectUrl: ''
                    }
                ],
                complete: false
            },
            step6: {
                people: [],
                complete: true
            },
            step7: {
                audience: [],
                complete: true
            },
            step8: {
                branding: {
                    logoImageUrl: '',
                    themeColor: ''
                },
                videoScreenBanners: [],
                complete: false
            },
            step9: {
                tnc: '',
                complete: true
            },
            currentStep: 0,
            lastEdited: 'Last edited: 18 Nov 2019 09:00 AM',
            people: {
                itemId: '',
                name: '',
                role: '',
                email: '',
                tel: '',
                verifyCode: ''
            },
            audience: {
                itemId: '',
                name: '',
                email: '',
                tel: '',
                verifyCode: ''
            }
        }
    },
    reducers: {
        update: (state, action) => {
            if (action.payload[ 'subFieldName' ]) {
                state.data[ 'step' + action.payload[ 'step' ] ][ action.payload[ 'fieldName' ] ][ action.payload[ 'subFieldName' ] ] = action.payload[ 'data' ];
            } else {
                state.data[ 'step' + action.payload[ 'step' ] ][ action.payload[ 'fieldName' ] ] = action.payload[ 'data' ];
            }
        },
        updateEventPeriod: (state, action) => {
            state.data[ 'step1' ][ 'eventPeriod' ][ action.payload.index ][ 'date' ] = action.payload.date;
        },
        addEventPeriod: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'step1' ][ 'eventPeriod' ].push({
                itemId: gf.randomString(5),
                date: ''
            });
        },
        deleteEventPeriod: (state, action) => {
            state.data[ 'step1' ][ 'eventPeriod' ].splice(action.payload.index, 1);
        },
        setupStepData: (state, action) => {
            state.data[ 'step' + action.payload.step ] = action.payload.data;
        },
        updateChannel: (state, action) => {
            if (action.payload[ 'subFieldName' ]) {
                state.data[ 'step2' ][ 'channel' ][ action.payload.index ][ action.payload[ 'fieldName' ] ][ action.payload[ 'subFieldName' ] ] = action.payload[ 'data' ];
            } else {
                state.data[ 'step2' ][ 'channel' ][ action.payload.index ][ action.payload[ 'fieldName' ] ] = action.payload[ 'data' ];
            }
        },
        addChannel: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'step2' ][ 'channel' ].push({
                itemId: gf.randomString(5),
                channelName: '',
                liveStreamingProvider: '',
                streamIngestDomain: '',
                broadcastPlaybackDomain: '',
                streamPrimaryAKey: '',
                streamIngestUrl: '',
                broadcastPlaybackUrl: '',
                broadcastPlaybackUrlStatus: '',
                streamKey: '',
                backupChannelOption: '',
                backupChannel: {
                    liveStreamingProvider: '',
                    streamIngestDomain: '',
                    broadcastPlaybackDomain: '',
                    streamPrimaryAKey: '',
                    streamIngestUrl: '',
                    broadcastPlaybackUrl: '',
                    broadcastPlaybackUrlStatus: '',
                    streamKey: ''
                }
            });
        },
        deleteChannel: (state, action) => {
            state.data[ 'step2' ][ 'channel' ].splice(action.payload.index, 1);
        },
        initHalls: (state, action) => {
            let gf = new GlobalFunctions();
            let data: any = JSON.stringify(state);
            data = JSON.parse(data)[ 'data' ][ 'step1' ][ 'eventPeriod' ];
            data.forEach((date) => {
                if (!state.data[ 'step3' ][ 'dates' ].find(o => o.date === date.date)) {
                    state.data[ 'step3' ][ 'dates' ].push(
                        {
                            date: date.date,
                            halls: [
                                {
                                    itemId: gf.randomString(5),
                                    hallName: '',
                                    channel: []
                                }
                            ]
                        }
                    );
                }

            });
        },
        updateHall: (state, action) => {
            if (action.payload[ 'subFieldName' ]) {
                state.data[ 'step3' ][ 'dates' ][ action.payload.dateIndex ][ 'halls' ][ action.payload.hallIndex ][ action.payload[ 'fieldName' ] ][ action.payload[ 'subFieldName' ] ] = action.payload[ 'data' ];
            } else {
                state.data[ 'step3' ][ 'dates' ][ action.payload.dateIndex ][ 'halls' ][ action.payload.hallIndex ][ action.payload[ 'fieldName' ] ] = action.payload[ 'data' ];
            }
        },
        addHall: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'step3' ][ 'dates' ][ action.payload.dateIndex ][ 'halls' ].push({
                itemId: gf.randomString(5),
                hallName: '',
                channel: []
            });
        },
        deleteHall: (state, action) => {
            state.data[ 'step3' ][ 'dates' ][ action.payload.dateIndex ][ 'halls' ].splice(action.payload.hallIndex, 1);
        },
        initSessions: (state, action) => {
            let gf = new GlobalFunctions();
            let data: any = JSON.stringify(state);
            data = JSON.parse(data)[ 'data' ][ 'step1' ][ 'eventPeriod' ];
            data.forEach((date) => {
                if (!state.data[ 'step4' ][ 'dates' ].find(o => o.date === date.date)) {
                    state.data[ 'step4' ][ 'dates' ].push(
                        {
                            date: date.date,
                            sessions: [
                                {
                                    itemId: gf.randomString(5),
                                    sessionName: '',
                                    startTime: '',
                                    endTime: '',
                                    orderingNumber: '',
                                    type: '',
                                    subSession: '',
                                    occupiedHalls: [],
                                    Panelists: [
                                        {
                                            itemId: gf.randomString(5),
                                            panelistName: '',
                                            panelistTitle: '',
                                            panelistRole: ''
                                        }
                                    ]
                                }
                            ]
                        }
                    );
                }

            });
        },
        updateSession: (state, action) => {
            if (action.payload[ 'subFieldName' ]) {
                state.data[ 'step4' ][ 'dates' ][ action.payload.dateIndex ][ 'sessions' ][ action.payload.sessionIndex ][ action.payload[ 'fieldName' ] ][ action.payload[ 'subFieldName' ] ] = action.payload[ 'data' ];
            } else {
                state.data[ 'step4' ][ 'dates' ][ action.payload.dateIndex ][ 'sessions' ][ action.payload.sessionIndex ][ action.payload[ 'fieldName' ] ] = action.payload[ 'data' ];
            }
        },
        addSession: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'step4' ][ 'dates' ][ action.payload.dateIndex ][ 'sessions' ].push({
                itemId: gf.randomString(5),
                sessionName: '',
                startTime: '',
                endTime: '',
                orderingNumber: '',
                type: '',
                subSession: '',
                occupiedHalls: [],
                Panelists: [
                    {
                        itemId: gf.randomString(5),
                        panelistName: '',
                        panelistTitle: '',
                        panelistRole: ''
                    }
                ]
            });
        },
        deleteSession: (state, action) => {
            state.data[ 'step4' ][ 'dates' ][ action.payload.dateIndex ][ 'sessions' ].splice(action.payload.hallIndex, 1);
        },
        updatePanelists: (state, action) => {
            if (action.payload[ 'subFieldName' ]) {
                state.data[ 'step4' ][ 'dates' ][ action.payload.dateIndex ][ 'sessions' ][ action.payload.sessionIndex ][ 'Panelists' ][ action.payload.panelistIndex ][ action.payload[ 'fieldName' ] ][ action.payload[ 'subFieldName' ] ] = action.payload[ 'data' ];
            } else {
                state.data[ 'step4' ][ 'dates' ][ action.payload.dateIndex ][ 'sessions' ][ action.payload.sessionIndex ][ 'Panelists' ][ action.payload.panelistIndex ][ action.payload[ 'fieldName' ] ] = action.payload[ 'data' ];
            }
        },
        addPanelist: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'step4' ][ 'dates' ][ action.payload.dateIndex ][ 'sessions' ][ action.payload.sessionIndex ][ 'Panelists' ].push({
                itemId: gf.randomString(5),
                panelistName: '',
                panelistTitle: '',
                panelistRole: ''
            });
        },
        deletePanelist: (state, action) => {
            state.data[ 'step4' ][ 'dates' ][ action.payload.dateIndex ][ 'sessions' ][ action.payload.sessionIndex ][ 'Panelists' ].splice(action.payload.panelistIndex, 1);
        },
        updateSponsor: (state, action) => {
            if (action.payload[ 'subFieldName' ]) {
                state.data[ 'step5' ][ 'sponsors' ][ action.payload.index ][ action.payload[ 'fieldName' ] ][ action.payload[ 'subFieldName' ] ] = action.payload[ 'data' ];
            } else {
                state.data[ 'step5' ][ 'sponsors' ][ action.payload.index ][ action.payload[ 'fieldName' ] ] = action.payload[ 'data' ];
            }
        },
        addSponsor: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'step5' ][ 'sponsors' ].push({
                itemId: gf.randomString(5),
                name: '',
                type: '',
                logoImageUrl: '',
                hyperlinkRedirectUrl: ''
            });
        },
        deleteSponsor: (state, action) => {
            state.data[ 'step5' ][ 'sponsors' ].splice(action.payload.index, 1);
        },
        addPeople: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'step6' ][ 'people' ].push(state.data[ 'people' ]);
            state.data[ 'people' ] = {
                itemId: gf.randomString(5),
                name: '',
                role: '',
                email: '',
                tel: '',
                verifyCode: gf.randomString(6, '0123456789')
            };
        },
        addPeopleByArray: (state, action) => {
            let gf = new GlobalFunctions();
            action.payload.data.forEach((v) => {
                state.data[ 'step6' ][ 'people' ].push(
                    {
                        itemId: gf.randomString(5),
                        name: v[ 'Name' ],
                        role: v[ 'Role' ],
                        email: v[ 'E-mail address' ],
                        tel: v[ 'Telephone number' ],
                        verifyCode: v[ 'Verify code' ]
                    }
                );
            });
        },
        deletePeople: (state, action) => {
            console.log(action.payload.ids);
            action.payload.ids.forEach(id => {
                state.data[ 'step6' ][ 'people' ] = state.data[ 'step6' ][ 'people' ].filter((obj) => {
                    return obj.itemId !== id;
                });
            });

        },
        updatePeople: (state, action) => {
            state.data[ 'people' ][ action.payload[ 'fieldName' ] ] = action.payload[ 'data' ];
        },
        cleanPeople: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'people' ] = {
                itemId: gf.randomString(5),
                name: '',
                role: '',
                email: '',
                tel: '',
                verifyCode: gf.randomString(6, '0123456789')
            };
        },
        addAudience: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'step7' ][ 'audience' ].push(state.data[ 'audience' ]);
            state.data[ 'audience' ] = {
                itemId: gf.randomString(5),
                name: '',
                email: '',
                tel: '',
                verifyCode: gf.randomString(6, '0123456789')
            };
        },
        addAudienceByArray: (state, action) => {
            let gf = new GlobalFunctions();
            action.payload.data.forEach((v) => {
                state.data[ 'step7' ][ 'audience' ].push(
                    {
                        itemId: gf.randomString(5),
                        name: v[ 'Name' ],
                        email: v[ 'E-mail address' ],
                        tel: v[ 'Telephone number' ],
                        verifyCode: v[ 'Verify code' ]
                    }
                );
            });
        },
        deleteAudience: (state, action) => {
            console.log(action.payload.ids);
            action.payload.ids.forEach(id => {
                state.data[ 'step7' ][ 'audience' ] = state.data[ 'step7' ][ 'audience' ].filter((obj) => {
                    return obj.itemId !== id;
                });
            });

        },
        updateAudience: (state, action) => {
            state.data[ 'audience' ][ action.payload[ 'fieldName' ] ] = action.payload[ 'data' ];
        },
        cleanAudience: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'audience' ] = {
                itemId: gf.randomString(5),
                name: '',
                email: '',
                tel: '',
                verifyCode: gf.randomString(6, '0123456789')
            };
        },
        updateVideoScreenBanners: (state, action) => {
            if (action.payload[ 'subFieldName' ]) {
                state.data[ 'step8' ][ 'videoScreenBanners' ][ action.payload.index ][ action.payload[ 'fieldName' ] ][ action.payload[ 'subFieldName' ] ] = action.payload[ 'data' ];
            } else {
                state.data[ 'step8' ][ 'videoScreenBanners' ][ action.payload.index ][ action.payload[ 'fieldName' ] ] = action.payload[ 'data' ];
            }
        },
        addVideoScreenBanners: (state, action) => {
            let gf = new GlobalFunctions();
            state.data[ 'step8' ][ 'videoScreenBanners' ].push({
                itemId: gf.randomString(5),
                position: '',
                imageUrl: '',
                hyperlinkRedirectUrl: '',
                date: '',
            });
        },
        deleteVideoScreenBanners: (state, action) => {
            state.data[ 'step8' ][ 'videoScreenBanners' ].splice(action.payload.index, 1);
        }
    }
});

export const {
    update,
    updateEventPeriod,
    addEventPeriod,
    deleteEventPeriod,
    setupStepData,
    updateChannel,
    addChannel,
    deleteChannel,
    initHalls,
    updateHall,
    addHall,
    deleteHall,
    initSessions,
    addSession,
    updateSession,
    deleteSession,
    updatePanelists,
    addPanelist,
    deletePanelist,
    updateSponsor,
    addSponsor,
    deleteSponsor,
    updatePeople,
    cleanPeople,
    deletePeople,
    addPeople,
    addPeopleByArray,
    updateAudience,
    cleanAudience,
    deleteAudience,
    addAudience,
    addAudienceByArray,
    updateVideoScreenBanners,
    addVideoScreenBanners,
    deleteVideoScreenBanners
} = stepDataSlice.actions;
export const selectStepData = (state) => state[ 'stepData' ];
export const stepDataReducer = stepDataSlice.reducer;
