import { InputAdornment } from '@material-ui/core';
import { Modal, Steps } from 'antd';
import { useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectStepData, updateChannel } from '../../store/slice/stepData';
import './Channels.scss';
import { FormButtonOption } from '../FormButtonOption/FormButtonOption';
import { FormSelectField } from '../FormSelectField/FormSelectField';
import { FormTextField } from '../FormTextField/FormTextField';
import { FormToggle } from '../FormToggle/FormToggle';
import { IconButton } from '../IconButton/IconButton';

export const Channels: React.FC<{
    channelsIndex: number,
    valueValid?: (value) => any
    deleteChannel?: (value) => any
}> = ({
          channelsIndex,
          valueValid = (value) => {
          },
          deleteChannel = (value) => {
          }
      }) => {
    const [formValid, setFormValid] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();

    const dataChange = (value, fieldName, subFieldName?) => {
        if (fieldName == 'liveStreamingProvider') {
            delete formValid[ 'streamIngestUrl' ];
            delete formValid[ 'broadcastPlaybackUrl' ];
            delete formValid[ 'broadcastPlaybackUrlStatus' ];
            delete formValid[ 'streamKey' ];
            delete formValid[ 'channelKey' ];
            delete formValid[ 'streamIngestDomain' ];
            delete formValid[ 'broadcastPlaybackDomain' ];
            delete formValid[ 'streamPrimaryAKey' ];
            setFormValid(formValid);
            valueValid(checkValueValid());
        }
        if (fieldName == 'backupChannel' && subFieldName == 'liveStreamingProvider') {
            delete formValid[ 'backupChannelstreamIngestUrl' ];
            delete formValid[ 'backupChannelbroadcastPlaybackUrl' ];
            delete formValid[ 'backupChannelbroadcastPlaybackUrlStatus' ];
            delete formValid[ 'backupChannelstreamKey' ];
            delete formValid[ 'backupChannelchannelKey' ];
            delete formValid[ 'backupChannelstreamIngestDomain' ];
            delete formValid[ 'backupChannelbroadcastPlaybackDomain' ];
            delete formValid[ 'backupChannelstreamPrimaryAKey' ];
            setFormValid(formValid);
            valueValid(checkValueValid());
        }
        if (fieldName == 'backupChannelOption' && value == 'No backup channel') {
            delete formValid[ 'backupChannelstreamIngestUrl' ];
            delete formValid[ 'backupChannelbroadcastPlaybackUrl' ];
            delete formValid[ 'backupChannelbroadcastPlaybackUrlStatus' ];
            delete formValid[ 'backupChannelstreamKey' ];
            delete formValid[ 'backupChannelchannelKey' ];
            delete formValid[ 'backupChannelstreamIngestDomain' ];
            delete formValid[ 'backupChannelbroadcastPlaybackDomain' ];
            delete formValid[ 'backupChannelstreamPrimaryAKey' ];
            delete formValid[ 'backupChannelliveStreamingProvider' ];
            setFormValid(formValid);
            valueValid(checkValueValid());
        }
        dispatch(updateChannel({
            index: channelsIndex,
            fieldName: fieldName,
            subFieldName: subFieldName,
            data: value
        }));
    };
    const valueValidCallback = (status, key) => {
        // setTimeout(() => {
        formValid[ key ] = status;
        setFormValid(formValid);
        valueValid(checkValueValid());
        // }, 1);
    };

    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        // console.log(formValid);
        return true;
    };

    const backupOption = [
        {
            label: 'No backup channel',
            value: 'No backup channel'
        },
        {
            label: 'Setup a backup channel',
            value: 'Setup a backup channel'
        }
    ];

    const showDeleteModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
        deleteChannel(channelsIndex);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <div className="channels-container">
            <div className="step-session">
                <div className="session-title">
                    Channel
                </div>
                <div className="input-row grid-cols-2">
                    <FormTextField value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'channelName' ]}
                                   isRequire={true}
                                   label={'Channel name'}
                                   valueValid={(v) => valueValidCallback(v, 'channelName')}
                                   valueChange={(v) => dataChange(v, 'channelName')}/>
                    <FormSelectField
                        value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'liveStreamingProvider' ]}
                        isRequire={true}
                        option={[
                            {
                                label: 'Aliyun ApsaraVideo Live',
                                value: 'Aliyun ApsaraVideo Live'
                            },
                            {
                                label: 'AWS IVS (Interactive Video Service)',
                                value: 'AWS IVS (Interactive Video Service)'
                            },
                            {
                                label: 'IBM Video Live Streaming',
                                value: 'IBM Video Live Streaming'
                            }
                        ]}
                        label={'Live streaming provider'}
                        valueValid={(v) => valueValidCallback(v, 'liveStreamingProvider')}
                        valueChange={(v) => dataChange(v, 'liveStreamingProvider')}/>
                </div>

                {states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'liveStreamingProvider' ] == 'Aliyun ApsaraVideo Live' &&
                <>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'streamIngestDomain' ]}
                            isRequire={true}
                            label={'Stream ingest domain'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">rtmps://</InputAdornment>
                            }}
                            placeholder="stream.mydomain.com"
                            valueValid={(v) => valueValidCallback(v, 'streamIngestDomain')}
                            valueChange={(v) => dataChange(v, 'streamIngestDomain')}/>
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'broadcastPlaybackDomain' ]}
                            isRequire={true}
                            label={'Broadcast playback domain'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">http://</InputAdornment>
                            }}
                            placeholder="live.mydomain.com"
                            valueValid={(v) => valueValidCallback(v, 'broadcastPlaybackDomain')}
                            valueChange={(v) => dataChange(v, 'broadcastPlaybackDomain')}/>
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'streamPrimaryAKey' ]}
                            isRequire={true}
                            label={'Stream primary A key'}
                            valueValid={(v) => valueValidCallback(v, 'streamPrimaryAKey')}
                            valueChange={(v) => dataChange(v, 'streamPrimaryAKey')}/>
                    </div>
                </>
                }
                {states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'liveStreamingProvider' ] == 'AWS IVS (Interactive Video Service)' &&
                <>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'streamIngestUrl' ]}
                            isRequire={true}
                            label={'Stream ingest URL'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">rtmps://</InputAdornment>
                            }}
                            placeholder="12345abc.global-contribute.live-video.net:443/app/"
                            valueValid={(v) => valueValidCallback(v, 'streamIngestUrl')}
                            valueChange={(v) => dataChange(v, 'streamIngestUrl')}/>
                    </div>
                    <div className="input-row grid-cols-8">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'broadcastPlaybackUrl' ]}
                            isRequire={true}
                            label={'Broadcast playback URL'}
                            className={'col-span-8'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">http://</InputAdornment>
                            }}
                            placeholder="123abc-west-2.playback.live-video.net/api/video/v1/channel.m3u8"
                            valueValid={(v) => valueValidCallback(v, 'broadcastPlaybackUrl')}
                            valueChange={(v) => dataChange(v, 'broadcastPlaybackUrl')}/>
                        <FormToggle
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'broadcastPlaybackUrlStatus' ]}
                            onText={'Public'}
                            offText={'Authorised'}
                            onValue={'public'}
                            offValue={'authorised'}
                            className={'col-span-1'}
                            valueValid={(v) => valueValidCallback(v, 'broadcastPlaybackUrlStatus')}
                            valueChange={(v) => dataChange(v, 'broadcastPlaybackUrlStatus')}/>
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'streamKey' ]}
                            isRequire={true}
                            label={'Stream key'}
                            valueValid={(v) => valueValidCallback(v, 'streamKey')}
                            valueChange={(v) => dataChange(v, 'streamKey')}/>
                    </div>
                </>
                }
                {states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'liveStreamingProvider' ] == 'IBM Video Live Streaming' &&
                <>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'streamIngestUrl' ]}
                            isRequire={true}
                            label={'Stream ingest URL'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">rtmps://</InputAdornment>
                            }}
                            placeholder="12345.fme.ustream.tv/ustreamVideo/12345678"
                            valueValid={(v) => valueValidCallback(v, 'streamIngestUrl')}
                            valueChange={(v) => dataChange(v, 'streamIngestUrl')}/>
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'broadcastPlaybackUrl' ]}
                            isRequire={true}
                            label={'Broadcast playback URL'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">http://</InputAdornment>
                            }}
                            placeholder="video.ibm.com/embed/342432434"
                            valueValid={(v) => valueValidCallback(v, 'broadcastPlaybackUrl')}
                            valueChange={(v) => dataChange(v, 'broadcastPlaybackUrl')}/>
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'channelKey' ]}
                            isRequire={true}
                            label={'Channel key'}
                            valueValid={(v) => valueValidCallback(v, 'channelKey')}
                            valueChange={(v) => dataChange(v, 'channelKey')}/>
                    </div>
                </>
                }

                <div className="input-row grid-cols-1">
                    <FormButtonOption
                        value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannelOption' ]}
                        label={'Backup'}
                        gridSize={'2'}
                        isRequire={true}
                        valueValid={(v) => valueValidCallback(v, 'backupChannelOption')}
                        valueChange={(v) => dataChange(v, 'backupChannelOption')}
                        option={backupOption}/>
                </div>
                {states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannelOption' ] == 'Setup a backup channel' &&
                <div className="input-row grid-cols-1">
                    <FormSelectField
                        value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'liveStreamingProvider' ]}
                        isRequire={true}
                        option={[
                            {
                                label: 'Aliyun ApsaraVideo Live',
                                value: 'Aliyun ApsaraVideo Live'
                            },
                            {
                                label: 'AWS IVS (Interactive Video Service)',
                                value: 'AWS IVS (Interactive Video Service)'
                            },
                            {
                                label: 'IBM Video Live Streaming',
                                value: 'IBM Video Live Streaming'
                            }
                        ]}
                        label={'Live streaming provider'}
                        valueValid={(v) => valueValidCallback(v, 'backupChannelliveStreamingProvider')}
                        valueChange={(v) => dataChange(v, 'backupChannel', 'liveStreamingProvider')}/>
                </div>
                }
                {states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannelOption' ] == 'Setup a backup channel' && states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'liveStreamingProvider' ] == 'Aliyun ApsaraVideo Live' &&
                <>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'streamIngestDomain' ]}
                            isRequire={true}
                            label={'Stream ingest domain'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">rtmps://</InputAdornment>
                            }}
                            placeholder="stream.mydomain.com"
                            valueValid={(v) => valueValidCallback(v, 'backupChannelstreamIngestDomain')}
                            valueChange={(v) => dataChange(v, 'backupChannel', 'streamIngestDomain')}/>
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'broadcastPlaybackDomain' ]}
                            isRequire={true}
                            label={'Broadcast playback domain'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">http://</InputAdornment>
                            }}
                            placeholder="live.mydomain.com"
                            valueValid={(v) => valueValidCallback(v, 'backupChannelbroadcastPlaybackDomain')}
                            valueChange={(v) => dataChange(v, 'backupChannel', 'broadcastPlaybackDomain')}/>
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'streamPrimaryAKey' ]}
                            isRequire={true}
                            label={'Stream primary A key'}
                            valueValid={(v) => valueValidCallback(v, 'backupChannelstreamPrimaryAKey')}
                            valueChange={(v) => dataChange(v, 'backupChannel', 'streamPrimaryAKey')}/>
                    </div>
                </>
                }
                {states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannelOption' ] == 'Setup a backup channel' && states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'liveStreamingProvider' ] == 'AWS IVS (Interactive Video Service)' &&
                <>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'streamIngestUrl' ]}
                            isRequire={true}
                            label={'Stream ingest URL'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">rtmps://</InputAdornment>
                            }}
                            placeholder="12345abc.global-contribute.live-video.net:443/app/"
                            valueValid={(v) => valueValidCallback(v, 'backupChannelstreamIngestUrl')}
                            valueChange={(v) => dataChange(v, 'backupChannel', 'streamIngestUrl')}/>
                    </div>
                    <div className="input-row grid-cols-8">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'broadcastPlaybackUrl' ]}
                            isRequire={true}
                            label={'Broadcast playback URL'}
                            className={'col-span-8'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">http://</InputAdornment>
                            }}
                            placeholder="123abc-west-2.playback.live-video.net/api/video/v1/channel.m3u8"
                            valueValid={(v) => valueValidCallback(v, 'backupChannelbroadcastPlaybackUrl')}
                            valueChange={(v) => dataChange(v, 'backupChannel', 'broadcastPlaybackUrl')}/>
                        <FormToggle
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'broadcastPlaybackUrlStatus' ]}
                            onText={'Public'}
                            offText={'Authorised'}
                            onValue={'public'}
                            offValue={'authorised'}
                            className={'col-span-1'}
                            valueValid={(v) => valueValidCallback(v, 'backupChannelbroadcastPlaybackUrlStatus')}
                            valueChange={(v) => dataChange(v, 'backupChannel', 'broadcastPlaybackUrlStatus')}/>
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'streamKey' ]}
                            isRequire={true}
                            label={'Stream key'}
                            valueValid={(v) => valueValidCallback(v, 'backupChannelstreamKey')}
                            valueChange={(v) => dataChange(v, 'backupChannel', 'streamKey')}/>
                    </div>
                </>
                }
                {states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannelOption' ] == 'Setup a backup channel' && states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'liveStreamingProvider' ] == 'IBM Video Live Streaming' &&
                <>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'streamIngestUrl' ]}
                            isRequire={true}
                            label={'Stream ingest URL'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">rtmps://</InputAdornment>
                            }}
                            placeholder="12345.fme.ustream.tv/ustreamVideo/12345678"
                            valueValid={(v) => valueValidCallback(v, 'backupChannelstreamIngestUrl')}
                            valueChange={(v) => dataChange(v, 'backupChannel', 'streamIngestUrl')}/>
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'broadcastPlaybackUrl' ]}
                            isRequire={true}
                            label={'Broadcast playback URL'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">http://</InputAdornment>
                            }}
                            placeholder="video.ibm.com/embed/342432434"
                            valueValid={(v) => valueValidCallback(v, 'backupChannelbroadcastPlaybackUrl')}
                            valueChange={(v) => dataChange(v, 'backupChannel', 'broadcastPlaybackUrl')}/>
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField
                            value={states[ 'data' ][ 'step2' ][ 'channel' ][ channelsIndex ][ 'backupChannel' ][ 'channelKey' ]}
                            isRequire={true}
                            label={'Channel key'}
                            valueValid={(v) => valueValidCallback(v, 'backupChannelchannelKey')}
                            valueChange={(v) => dataChange(v, 'backupChannel', 'channelKey')}/>
                    </div>
                </>
                }

                <Modal title="Delete Channel" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                       okText={'Confirm'}>
                    <p>Confirm to delete this channel?</p>
                </Modal>
                {states[ 'data' ][ 'step2' ][ 'channel' ].length > 1 &&
                <div onClick={showDeleteModal}>
                    <IconButton icon={'delete'} text={'Delete this channel'}/>
                </div>
                }
            </div>
        </div>
    );
};
