import { Chip, InputAdornment, TextField } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import './Step9.scss';
import { FormDatePicker } from '../../../components/FormDatePicker/FormDatePicker';
import { FormTextField } from '../../../components/FormTextField/FormTextField';
import { IconButton } from '../../../components/IconButton/IconButton';
import { GlobalFunctions } from '../../../GlobalFunctions';
import style from './Step9.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStepData, update
} from '../../../store/slice/stepData';
import { Modal, notification } from 'antd';
import { Redirect, useHistory } from 'react-router-dom';

export const Step9: React.FC<{}> = ({}) => {
    const gf = new GlobalFunctions();
    const [formValid, setFormValid] = useState({});
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    let history = useHistory();

    const dataChange = (value, fieldName, subFieldName?) => {
        dispatch(update({
            step: 9,
            fieldName: fieldName,
            subFieldName: subFieldName,
            data: value
        }));
    };

    const valueValidCallback = (status, key) => {
        formValid[ key ] = status;
        setFormValid(formValid);
        dispatch(update({
            step: 9,
            fieldName: 'complete',
            data: checkValueValid()
        }));
    };

    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        return true;
    };

    const nextStep = () => {
        if (states[ 'data' ][ 'step9' ][ 'complete' ]) {
            gf.setLocalStorage('step9', states[ 'data' ][ 'step9' ]);
            notification[ 'success' ]({
                message: 'Processing complete'
            });
            history.push('/step/9');
            window.scrollTo(0, 0);
        }
    };
    return (
        <div className="step-page-content-wrapper">
            <div className="step-session">
                <div className="session-title">
                    Terms & Conditions <span className="sub-title">(optional)</span>
                </div>
                <div className="input-row grid-cols-1">
                    <FormTextField value={states[ 'data' ][ 'step9' ][ 'tnc' ]}
                                   isRequire={false}
                                   label={'T&C about webinar'}
                                   multiline={true}
                                   multilineRows={8}
                                   valueValid={(v) => valueValidCallback(v, 'tnc')}
                                   valueChange={(v) => dataChange(v, 'tnc')}/>
                </div>

            </div>
            <div className={`next-step ${states[ 'data' ][ 'step9' ][ 'complete' ] ? '' : 'disabled'}`}
                 onClick={nextStep}>
                Finish
            </div>
        </div>
    );
};
