import { configureStore } from "@reduxjs/toolkit";
import { shareDataReducer } from './slice/shareData';
import { stepDataReducer } from './slice/stepData';

export default configureStore({
    reducer: {
        shareData: shareDataReducer,
        stepData: stepDataReducer,
    },
});
