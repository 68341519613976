import { Steps } from 'antd';
import { useState } from 'react';
import * as React from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './StepPage.scss';
import { StepMenu } from '../../components/StepMenu/StepMenu';
import { GlobalFunctions } from '../../GlobalFunctions';
import { selectStepData, setupStepData } from '../../store/slice/stepData';
import { Step1 } from './Step1/Step1';
import { Step2 } from './Step2/Step2';
import { Step3 } from './Step3/Step3';
import { Step4 } from './Step4/Step4';
import Sticky from 'react-sticky-el';
import { Step5 } from './Step5/Step5';
import { Step6 } from './Step6/Step6';
import { Step7 } from './Step7/Step7';
import { Step8 } from './Step8/Step8';
import { Step9 } from './Step9/Step9';

const { Step } = Steps;

export const StepPage: React.FC<{}> = ({}) => {
    const [init, setInit] = useState(false);
    const gf = new GlobalFunctions();
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    let history = useHistory();

    const setupData = (step) => {
        dispatch(setupStepData({
            step: step,
            data: gf.getLocalStorage('step' + step)
        }));
    };
    if (!init) {
        setInit(true);
        // setTimeout(() => {
        [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach(v => {
            if (gf.getLocalStorage('step' + v)) {
                setupData(v);
            }
        });
        // }, 1);
    }
    return (
        <Router>
            <div className="step-page-wrapper">
                <div className="container">
                    <div className="step-page-container">
                        <div className="step-menu">
                            <Sticky topOffset={-150}  className={'sticky-menu'}>
                                <StepMenu/>
                            </Sticky>
                        </div>
                        <div className="step-page">
                            <div>
                                <Switch>
                                    <Route exact path="/step/1">
                                        <Step1/>
                                    </Route>
                                    <Route exact path="/step/2">
                                        <Step2/>
                                    </Route>
                                    <Route exact path="/step/3">
                                        <Step3/>
                                    </Route>
                                    <Route exact path="/step/4">
                                        <Step4/>
                                    </Route>
                                    <Route exact path="/step/5">
                                        <Step5/>
                                    </Route>
                                    <Route exact path="/step/6">
                                        <Step6/>
                                    </Route>
                                    <Route exact path="/step/7">
                                        <Step7/>
                                    </Route>
                                    <Route exact path="/step/8">
                                        <Step8/>
                                    </Route>
                                    <Route exact path="/step/9">
                                        <Step9/>
                                    </Route>
                                    <Route>
                                        Not found
                                    </Route>
                                </Switch>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
};
