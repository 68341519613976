import { InputAdornment } from '@material-ui/core';
import { Modal, Steps } from 'antd';
import { useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectStepData, updateChannel, updatePanelists } from '../../store/slice/stepData';
import './Panelists.scss';
import { FormButtonOption } from '../FormButtonOption/FormButtonOption';
import { FormSelectField } from '../FormSelectField/FormSelectField';
import { FormTextField } from '../FormTextField/FormTextField';
import { FormToggle } from '../FormToggle/FormToggle';
import { IconButton } from '../IconButton/IconButton';

export const Panelists: React.FC<{
    index: number,
    sessionsIndex: number,
    panelistIndex: number,
    valueValid?: (value) => any
    deleteChannel?: (value) => any
}> = ({
          index,
          sessionsIndex,
          panelistIndex,
          valueValid = (value) => {
          },
          deleteChannel = (value) => {
          }
      }) => {
    const [formValid, setFormValid] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    const panelistData = states[ 'data' ][ 'step4' ][ 'dates' ][ index ][ 'sessions' ][ sessionsIndex ][ 'Panelists' ][ panelistIndex ];

    const dataChange = (value, fieldName, subFieldName?) => {
        dispatch(updatePanelists({
            dateIndex: index,
            sessionIndex: sessionsIndex,
            panelistIndex: panelistIndex,
            fieldName: fieldName,
            subFieldName: subFieldName,
            data: value
        }));
    };
    const valueValidCallback = (status, key) => {
        // setTimeout(() => {
        formValid[ key ] = status;
        setFormValid(formValid);
        valueValid(checkValueValid());
        // }, 1);
    };

    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        // console.log(formValid);
        return true;
    };
    return (
        <div className="panelists-container">
            <div className="input-row grid-cols-1 small-margin-bottom">
                <FormTextField
                    value={panelistData[ 'panelistName' ]}
                    label={'Panelist name'}
                    valueValid={(value) => valueValidCallback(value, 'panelistName')}
                    valueChange={(value) => dataChange(value, 'panelistName')}/>
            </div>
            <div className="input-row grid-cols-12">
                <FormTextField
                    value={panelistData[ 'panelistTitle' ]}
                    label={'Panelist title'}
                    className={'col-span-8'}
                    valueValid={(value) => valueValidCallback(value, 'panelistTitle')}
                    valueChange={(value) => dataChange(value, 'panelistTitle')}/>
                <FormSelectField
                    value={panelistData[ 'panelistRole' ]}
                    label={'Panelist role'}
                    className={'col-span-4'}
                    option={[
                        {
                            label: 'Please Select',
                            value: ''
                        },
                        {
                            label: 'Speaker',
                            value: 'Speaker'
                        },
                        {
                            label: 'Moderator',
                            value: 'Moderator'
                        },
                        {
                            label: 'Key Speaker',
                            value: 'Key Speaker'
                        },
                        {
                            label: 'Key Moderator',
                            value: 'Key Moderator'
                        }
                    ]}
                    valueValid={(value) => valueValidCallback(value, 'panelistRole')}
                    valueChange={(value) => dataChange(value, 'panelistRole')}/>
            </div>
        </div>
    );
};
