import { TextField } from '@material-ui/core';
import { Switch } from 'antd';
import { useState } from 'react';
import * as React from 'react';
import { GlobalFunctions } from '../../GlobalFunctions';
import style from './FormToggle.module.scss';

export const FormToggle: React.FC<{
    value: string,
    onText: string,
    onValue: string,
    offText: string,
    offValue: string,
    className?: string,
    valueChange?: (value) => any,
    valueValid?: (value) => any,
}> = ({
          className = '',
          value,
          onText,
          onValue,
          offText,
          offValue,
          valueChange = (value) => {
          },
          valueValid = (value) => {
          }
      }) => {
    const [showError, setShowError] = useState(false);
    const [valueValidState, setValueValidState] = useState(null);
    const gf = new GlobalFunctions();

    const dataChange = (event) => {
        setShowError(true);
        valueChange(event.target.value);
        checkValueValid();
    };

    const checkValueValid = () => {
        let status = !gf.fieldValidationError(value, true).error;
        if (valueValidState !== status) {
            valueValid(status);
            setValueValidState(status);
        }
    };
    const toggleChange = (result) => {
        setShowError(true);
        if (result) {
            valueChange(onValue);
        } else {
            valueChange(offValue);
        }
        checkValueValid();
    };
    checkValueValid();
    if (!value) {
        // setTimeout(()=>{
        valueChange(offValue);
        // },1);
    }
    return (
        <div className={`text-input ${className} ${style[ 'form-toggle-field' ]}`}>
            <Switch className={style[ 'toggle' ]} checkedChildren={onText} unCheckedChildren={offText} checked={value === onValue}
                    onChange={(e) => toggleChange(e)}/>
        </div>
    );
};
