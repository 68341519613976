import { Chip, TextField } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import './Step1.scss';
import { FormDatePicker } from '../../../components/FormDatePicker/FormDatePicker';
import { FormTextField } from '../../../components/FormTextField/FormTextField';
import { IconButton } from '../../../components/IconButton/IconButton';
import { GlobalFunctions } from '../../../GlobalFunctions';
import style from './Step1.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStepData,
    update,
    updateEventPeriod,
    addEventPeriod,
    deleteEventPeriod
} from '../../../store/slice/stepData';
import { Modal, notification } from 'antd';
import { Redirect, useHistory } from 'react-router-dom';

export const Step1: React.FC<{}> = ({}) => {
    const gf = new GlobalFunctions();
    const [formValid, setFormValid] = useState({});
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    let history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteEventDateIndex, setDeleteEventDateIndex] = useState(0);
    const [deleteEventDateItemId, setDeleteEventDateItemId] = useState('');

    const showDeleteEventPeriodModal = (index,itemId) => {
        setIsModalVisible(true);
        setDeleteEventDateIndex(index);
        setDeleteEventDateItemId(itemId);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        eventPeriodDelete(deleteEventDateIndex);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const dataChange = (value, fieldName, subFieldName?) => {
        dispatch(update({
            step: 1,
            fieldName: fieldName,
            subFieldName: subFieldName,
            data: value
        }));
    };
    const eventPeriodChange = (date, index) => {
        dispatch(updateEventPeriod({
            date: date,
            index: index
        }));
    };
    const eventPeriodDelete = (index) => {
        delete formValid[ 'eventPeriod' + deleteEventDateItemId ];
        setFormValid(formValid);
        dispatch(deleteEventPeriod({
            index: index
        }));
        dispatch(update({
            step: 1,
            fieldName: 'complete',
            data: checkValueValid()
        }));
    };
    const addEventPeriodF = () => {
        dispatch(addEventPeriod({}));
    };
    const eventDays = (eventPeriod) => {
        let day = 0;
        eventPeriod.forEach(v => {
            if (v) {
                day++;
            }
        });

        let dateStr = '';
        if (day) {
            dateStr = day.toString() + ' day' + (day > 1 ? 's' : '');
        }
        return dateStr;
    };

    const checkEventPeriod = (dates) => {
        let check = true;
        dates.forEach(v => {
            if (!v) {
                check = false;
            }
        });
        return check;
    };

    const valueValidCallback = (status, key) => {
        // setTimeout(() => {
        formValid[ key ] = status;
        setFormValid(formValid);
        dispatch(update({
            step: 1,
            fieldName: 'complete',
            data: checkValueValid()
        }));
        // }, 1);
    };

    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        return true;
    };

    const nextStep = () => {
        if (states[ 'data' ][ 'step1' ][ 'complete' ]) {
            gf.setLocalStorage('step1', states[ 'data' ][ 'step1' ]);
            notification[ 'success' ]({
                message: 'Processing complete'
            });
            history.push('/step/2');
            window.scrollTo(0, 0);
        }
    };
    return (
        <div className="step-page-content-wrapper">
            <div className="step-session">
                <div className="session-title">
                    Webinar Profile
                </div>
                <div className="input-row grid-cols-2">
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'eventName' ]}
                                   isRequire={true}
                                   label={'Event name'}
                                   valueValid={(v) => valueValidCallback(v, 'eventName')}
                                   valueChange={(v) => dataChange(v, 'eventName')}/>
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'eventTagline' ]}
                                   label={'Event tagline (optional)'}
                                   valueValid={(v) => valueValidCallback(v, 'eventTagline')}
                                   valueChange={(v) => dataChange(v, 'eventTagline')}/>
                </div>
                <div className="input-row grid-cols-1 small-margin-bottom">
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'customUrl' ]}
                                   valueValid={(v) => valueValidCallback(v, 'customUrl')}
                                   label={'Custom URL'} valueChange={(v) => dataChange(v, 'customUrl')}/>
                </div>
                <div className="input-row grid-cols-1">
                    <a href={`https://webinar.mysellpay.com/${states[ 'data' ][ 'step1' ][ 'customUrl' ] ? states[ 'data' ][ 'step1' ][ 'customUrl' ] : states[ 'data' ][ 'step1' ][ 'defaultPermalink' ]}/`}
                       target="_blank">
                        <div className={style.link}>
                            https://webinar.mysellpay.com/{states[ 'data' ][ 'step1' ][ 'customUrl' ] ? states[ 'data' ][ 'step1' ][ 'customUrl' ] : states[ 'data' ][ 'step1' ][ 'defaultPermalink' ]}/
                        </div>
                    </a>
                </div>

            </div>
            <div className="step-session">
                <div className="session-title has-chip">
                    <div className="title">
                        Event Period
                    </div>
                    {eventDays(states[ 'data' ][ 'step1' ][ 'eventPeriod' ]) && <div className="chip">
                        <Chip label={eventDays(states[ 'data' ][ 'step1' ][ 'eventPeriod' ])}/>
                    </div>}
                </div>

                <Modal title="Delete Event Date" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                       okText={'Confirm'}>
                    <p>Confirm to delete this event date?</p>
                </Modal>
                {states[ 'data' ][ 'step1' ][ 'eventPeriod' ].map((v, i) => {
                    return (
                        <div className="input-row grid-cols-2 items-center" key={i}>
                            <FormDatePicker value={v[ 'date' ]} label={'Event date'}
                                            isRequire={true}
                                            valueValid={(value) => valueValidCallback(value, 'eventPeriod' + v[ 'itemId' ])}
                                            valueChange={(value) => eventPeriodChange(value, i)}/>
                            <div>
                                {states[ 'data' ][ 'step1' ][ 'eventPeriod' ].length > 1 &&
                                <div onClick={() => showDeleteEventPeriodModal(i, v[ 'itemId' ])}>
                                    <IconButton icon={'delete'} text={'Delete'}/>
                                </div>
                                }
                            </div>
                        </div>
                    );
                })}

                {checkEventPeriod(states[ 'data' ][ 'step1' ][ 'eventPeriod' ]) &&
                <div onClick={addEventPeriodF}>
                    <IconButton icon={'add'} text={'Add date'}/>
                </div>
                }
            </div>
            <div className="step-session">
                <div className="session-title">
                    General Enquiry <span className="sub-title">(optional)</span>
                </div>
                <div className="input-row grid-cols-3">
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'generalEnquiry' ][ 'contactPerson' ]}
                                   isRequire={false}
                                   className={'col-span-2'}
                                   label={'Contact person'}
                                   valueValid={(v) => valueValidCallback(v, 'generalEnquirycontactPerson')}
                                   valueChange={(v) => dataChange(v, 'generalEnquiry', 'contactPerson')}/>
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'generalEnquiry' ][ 'email' ]}
                                   isRequire={false}
                                   label={'E-mail'}
                                   type={'email'}
                                   valueValid={(v) => valueValidCallback(v, 'generalEnquiryemail')}
                                   valueChange={(v) => dataChange(v, 'generalEnquiry', 'email')}/>
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'generalEnquiry' ][ 'phone' ]}
                                   isRequire={false}
                                   label={'Phone'}
                                   type={'phone'}
                                   valueValid={(v) => valueValidCallback(v, 'generalEnquiryphone')}
                                   valueChange={(v) => dataChange(v, 'generalEnquiry', 'phone')}/>
                </div>
            </div>
            <div className="step-session">
                <div className="session-title">
                    Technical Enquiry <span className="sub-title">(optional)</span>
                </div>
                <div className="input-row grid-cols-3">
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'technicalEnquiry' ][ 'contactPerson' ]}
                                   isRequire={false}
                                   className={'col-span-2'}
                                   label={'Contact person'}
                                   valueValid={(v) => valueValidCallback(v, 'technicalEnquirycontactPerson')}
                                   valueChange={(v) => dataChange(v, 'technicalEnquiry', 'contactPerson')}/>
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'technicalEnquiry' ][ 'email' ]}
                                   isRequire={false}
                                   label={'E-mail'}
                                   type={'email'}
                                   valueValid={(v) => valueValidCallback(v, 'technicalEnquiryemail')}
                                   valueChange={(v) => dataChange(v, 'technicalEnquiry', 'email')}/>
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'technicalEnquiry' ][ 'phone' ]}
                                   isRequire={false}
                                   label={'Phone'}
                                   type={'phone'}
                                   valueValid={(v) => valueValidCallback(v, 'technicalEnquiryphone')}
                                   valueChange={(v) => dataChange(v, 'technicalEnquiry', 'phone')}/>
                </div>
            </div>
            <div className="step-session">
                <div className="session-title">
                    Registration Enquiry <span className="sub-title">(optional)</span>
                </div>
                <div className="input-row grid-cols-3">
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'registrationEnquiry' ][ 'contactPerson' ]}
                                   isRequire={false}
                                   className={'col-span-2'}
                                   label={'Contact person'}
                                   valueValid={(v) => valueValidCallback(v, 'registrationEnquirycontactPerson')}
                                   valueChange={(v) => dataChange(v, 'registrationEnquiry', 'contactPerson')}/>
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'registrationEnquiry' ][ 'email' ]}
                                   isRequire={false}
                                   label={'E-mail'}
                                   type={'email'}
                                   valueValid={(v) => valueValidCallback(v, 'registrationEnquiryemail')}
                                   valueChange={(v) => dataChange(v, 'registrationEnquiry', 'email')}/>
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'registrationEnquiry' ][ 'phone' ]}
                                   isRequire={false}
                                   label={'Phone'}
                                   type={'phone'}
                                   valueValid={(v) => valueValidCallback(v, 'registrationEnquiryphone')}
                                   valueChange={(v) => dataChange(v, 'registrationEnquiry', 'phone')}/>
                </div>
            </div>
            <div className="step-session">
                <div className="session-title">
                    Description <span className="sub-title">(optional)</span>
                </div>
                <div className="input-row grid-cols-1">
                    <FormTextField value={states[ 'data' ][ 'step1' ][ 'description' ]}
                                   isRequire={false}
                                   multiline={true}
                                   multilineRows={8}
                                   label={'More description about webinar for your target audiences'}
                                   valueValid={(v) => valueValidCallback(v, 'description')}
                                   valueChange={(v) => dataChange(v, 'description')}/>
                </div>
            </div>
            <div className={`next-step ${states[ 'data' ][ 'step1' ][ 'complete' ] ? '' : 'disabled'}`}
                 onClick={nextStep}>
                Next Step
            </div>
        </div>
    );
};
