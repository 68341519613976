import { InputAdornment, TextField } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import './Step6.scss';
import { FileDrop } from 'react-file-drop';
import { FormButtonOption } from '../../../components/FormButtonOption/FormButtonOption';
import style from '../../../components/FormButtonOption/FormButtonOption.module.scss';
import { FormDatePicker } from '../../../components/FormDatePicker/FormDatePicker';
import { FormTextField } from '../../../components/FormTextField/FormTextField';
import { IconButton } from '../../../components/IconButton/IconButton';
import { Sponsor } from '../../../components/Sponsor/Sponsor';
import { GlobalFunctions } from '../../../GlobalFunctions';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStepData,
    update, deleteSponsor, addSponsor, updatePeople, cleanPeople, addPeople, addPeopleByArray, deletePeople
} from '../../../store/slice/stepData';
import { Modal, notification } from 'antd';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import template from './../../../assets/doc/people.csv';

export const Step6: React.FC<{}> = ({}) => {
    const gf = new GlobalFunctions();
    const [formValid, setFormValid] = useState({});
    const [formPeopleValid, setFormPeopleValid] = useState({});
    const [canSave, setCanSave] = useState(false);
    const [addPeopleModelStatus, setAddPeopleModelStatus] = useState(false);
    const [importPeopleModelStatus, setImportPeopleModelStatus] = useState(false);
    const [reload, setReload] = useState(false);
    const [selectedPeople, setSelectedPeople] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    let history = useHistory();
    const addSponsorClick = () => {
        dispatch(addSponsor({}));
    };
    const peopleChange = (value, field) => {
        dispatch(updatePeople({
            fieldName: field,
            data: value
        }));
    };

    const valueValidPeopleCallback = (status, key) => {
        formPeopleValid[ key ] = status;
        setFormPeopleValid(formPeopleValid);
        setCanSave(checkFormPeopleValidValid());
    };

    const valueValidCallback = (status, key) => {
        // setTimeout(() => {
        formValid[ key ] = status;
        setFormValid(formValid);
        dispatch(update({
            step: 6,
            fieldName: 'complete',
            data: checkValueValid()
        }));
        // }, 1);
    };
    const deleteSponsorCallback = (index, itemId) => {
        delete formValid[ 'sponsor' + itemId ];
        setFormValid(formValid);
        dispatch(deleteSponsor({
            index: index
        }));
        dispatch(update({
            step: 5,
            fieldName: 'complete',
            data: checkValueValid()
        }));
    };

    const checkFormPeopleValidValid = () => {
        for (let key in formPeopleValid) {
            if (!formPeopleValid[ key ]) {
                return false;
            }
        }
        return true;
    };
    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        return true;
    };

    const nextStep = () => {
        if (states[ 'data' ][ 'step6' ][ 'complete' ]) {
            gf.setLocalStorage('step6', states[ 'data' ][ 'step6' ]);
            notification[ 'success' ]({
                message: 'Processing complete'
            });
            history.push('/step/7');
            window.scrollTo(0, 0);
        }
    };
    const showAddPeopleModal = () => {
        dispatch(cleanPeople({}));
        setAddPeopleModelStatus(true);
    };
    const showImportPeopleModal = () => {
        setImportPeopleModelStatus(true);
    };

    const roleOption = [
        {
            label: 'Host',
            subLabel: 'Super Control',
            value: 'host'
        },
        {
            label: 'Co-host',
            subLabel: 'Access Q&A <br>(projection screen)<br>in all sessions',
            value: 'co-host'
        },
        {
            label: 'Panelist',
            subLabel: 'Access Q&A <br>(projection screen)<br>in current sessions',
            value: 'panelist'
        }
    ];
    const addPeopleHandler = () => {
        if (canSave) {
            dispatch(addPeople({}));
            setAddPeopleModelStatus(false);
        }
    };

    const dropFile = (files, event) => {
        if (files[ 0 ][ 'type' ] === 'text/csv') {
            var reader = new FileReader();
            reader.onload = function (e) {
                // Use reader.result
                console.log(JSON.stringify(reader.result));
                let csv = reader.result;
                var lines = csv.toString().split('\r\n');
                var result = [];
                var headers = lines[ 0 ].split(',');
                for (var i = 1; i < lines.length; i++) {
                    var obj = {};
                    var currentline = lines[ i ].split(',');

                    for (var j = 0; j < headers.length; j++) {
                        obj[ headers[ j ] ] = currentline[ j ];
                    }
                    result.push(obj);
                }
                dispatch(addPeopleByArray({
                    data: result
                }));
                setImportPeopleModelStatus(false);

                notification[ 'success' ]({
                    message: 'Import success'
                });
            };
            reader.readAsText(files[ 0 ]);
        } else {
            notification[ 'error' ]({
                message: 'Invalid file'
            });
        }
    };
    const togglePeople = (index) => {
        if (index == 'all') {
            let check = checkPeopleNotSelect();
            states[ 'data' ][ 'step6' ][ 'people' ].forEach((v, i) => {
                selectedPeople[ i ] = !check;
            });
        } else {
            let status = false;
            if (!selectedPeople[ index ]) {
                status = true;
            }
            selectedPeople[ index ] = status;
        }
        setSelectedPeople(selectedPeople);
        setReload(!reload);
    };

    const checkPeopleHasSelect = () => {
        let check = false;
        states[ 'data' ][ 'step6' ][ 'people' ].forEach((v, i) => {
            if (selectedPeople[ i ]) {
                check = true;
            }
        });
        return check;
    };

    const checkPeopleNotSelect = () => {
        let check = true;
        states[ 'data' ][ 'step6' ][ 'people' ].forEach((v, i) => {
            if (!selectedPeople[ i ]) {
                check = false;
            }
        });
        return check;
    };
    const handleOk = () => {
        let itemsIds = [];
        for (let key in selectedPeople) {
            if (selectedPeople[ key ]) {
                itemsIds.push(states[ 'data' ][ 'step6' ][ 'people' ][ key ][ 'itemId' ]);
            }
        }
        dispatch(deletePeople({
            ids: itemsIds
        }));
        setIsModalVisible(false);
        setSelectedPeople({});
        setReload(!reload);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="step-page-content-wrapper">
            {importPeopleModelStatus &&
            <div className="import-people-model">
                <div className="mask" onClick={() => setImportPeopleModelStatus(false)}/>
                <div className="modal-container">
                    <div className="modal-title flex items-center">
                        <div className="text">Import from CSV</div>
                        <div className="template-btn ml-auto">
                            <a href={template}>
                                <IconButton icon={'download'} text={'Download a CSV sample'}/>
                            </a>
                        </div>
                    </div>
                    <div className="file-drop-zone">
                        <FileDrop
                            onDrop={dropFile}
                        >
                            <div className="text">
                                Drop and drop a CSV file here or click
                            </div>
                            <div className="icon">
                                <span className="webinar-icon-upload"/>
                            </div>
                        </FileDrop>
                    </div>

                </div>
            </div>
            }

            {addPeopleModelStatus &&
            <div className="add-people-model">
                <div className="mask" onClick={() => setAddPeopleModelStatus(false)}/>
                <div className="modal-container">
                    <div className="session-title">
                        Add a people
                        <div className="close-btn" onClick={() => setAddPeopleModelStatus(false)}>
                            <span className="webinar-icon-close"></span>
                        </div>
                    </div>

                    <div className="input-row grid-cols-1">
                        <FormTextField value={states[ 'data' ][ 'people' ][ 'name' ]}
                                       isRequire={true}
                                       label={'Name'}
                                       valueValid={(v) => valueValidPeopleCallback(v, 'name')}
                                       valueChange={(v) => peopleChange(v, 'name')}/>
                    </div>

                    <div className="input-row grid-cols-1">

                        <FormButtonOption
                            value={states[ 'data' ][ 'people' ][ 'role' ]}
                            label={'Role'}
                            gridSize={'3'}
                            isRequire={true}
                            valueValid={(value) => valueValidPeopleCallback(value, 'role')}
                            valueChange={(value) => peopleChange(value, 'role')}
                            option={roleOption}/>
                    </div>

                    <div className="input-label">
                        Sign-in
                    </div>
                    <div className="input-row grid-cols-1">
                        <FormTextField value={states[ 'data' ][ 'people' ][ 'email' ]}
                                       isRequire={true}
                                       label={'E-mail address'}
                                       type={'email'}
                                       valueValid={(v) => valueValidPeopleCallback(v, 'email')}
                                       valueChange={(v) => peopleChange(v, 'email')}/>
                    </div>
                    <div className="input-row grid-cols-2">
                        <FormTextField value={states[ 'data' ][ 'people' ][ 'tel' ]}
                                       isRequire={true}
                                       label={'Telephone number'}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">+852.</InputAdornment>
                                       }}
                                       valueValid={(v) => valueValidPeopleCallback(v, 'tel')}
                                       valueChange={(v) => peopleChange(v, 'tel')}/>
                        <FormTextField value={states[ 'data' ][ 'people' ][ 'verifyCode' ]}
                                       isRequire={true}
                                       label={'Verify code'}
                                       valueValid={(v) => valueValidPeopleCallback(v, 'verifyCode')}
                                       valueChange={(v) => peopleChange(v, 'verifyCode')}/>
                    </div>

                    <div className={`save-btn ${canSave ? '' : 'disabled'}`} onClick={addPeopleHandler}>
                        Save
                    </div>
                </div>
            </div>
            }

            <Modal title="Delete People" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                   okText={'Confirm'}>
                <p>Confirm to delete these people?</p>
            </Modal>
            <div className="step-session">
                <div className="session-title">
                    People to access control panel
                </div>
                <div className={'table-container'}>
                    {states[ 'data' ][ 'step6' ][ 'people' ].length == 0 && <div className="no-data">
                        No people found
                    </div>}
                    {states[ 'data' ][ 'step6' ][ 'people' ].length > 0 &&
                    <div className="data-container">
                        {!checkPeopleHasSelect() && <div className="control-header">
                            <div className="search-bar">
                                <TextField className="input" InputProps={{
                                    startAdornment: <InputAdornment position="start"><span
                                        className="webinar-icon-search"/></InputAdornment>
                                }} placeholder={'Search by name of e-mail or tel-number'} label={'Search'}
                                           variant="outlined"/>
                            </div>
                            <div className="export-btn">Export</div>
                        </div>}
                        {checkPeopleHasSelect() &&
                        <div className="action-btns">
                            <div className="action-btn" onClick={() => setIsModalVisible(true)}>
                                <IconButton icon={'delete'} text={'Delete these people'}/>
                            </div>
                            <div className="action-btn">
                                <IconButton icon={'send'} text={'Send verify code'}/>
                            </div>
                        </div>
                        }
                        <div className="table-wrapper people-table">
                            <table>
                                <tr>
                                    <th>
                                        <div className="checkbox" onClick={() => togglePeople('all')}></div>
                                    </th>
                                    <th>Select</th>
                                    <th>Name</th>
                                    <th>E-mail/ Tel-number</th>
                                    <th>Verify code</th>
                                    <th>Role</th>
                                    <th>Last login</th>
                                </tr>
                                {states[ 'data' ][ 'step6' ][ 'people' ].map((v, i) => {
                                    return (
                                        <tr>
                                            <td valign="top">
                                                <div className={`checkbox ${selectedPeople[ i ] ? 'active' : ''}`}
                                                     onClick={() => togglePeople(i)}/>
                                            </td>
                                            <td valign="top">
                                                <div className="status-ball"></div>
                                            </td>
                                            <td valign="top">
                                                <div className="name">{v[ 'name' ]}</div>
                                            </td>
                                            <td valign="top">
                                                <div className="email">{v[ 'email' ]}</div>
                                                <div className="tel">{v[ 'tel' ]}</div>
                                            </td>
                                            <td valign="top">
                                                <div className="verify-code">
                                                    {v[ 'verifyCode' ]}
                                                </div>
                                            </td>
                                            <td valign="top">
                                                <div className="role">
                                                    {v[ 'role' ]}
                                                </div>
                                            </td>
                                            <td valign="top">
                                                <div className="last-login-time">
                                                    10 May 2021
                                                </div>
                                                <div className="last-login-by">
                                                    by E-mail
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </div>
                    </div>
                    }
                </div>

            </div>

            <div className="grid grid-flow-col gap-12 grid-cols-2">
                <div className="step-session add-item-control-btn " onClick={() => showAddPeopleModal()}>
                    <div className="icon">
                        <span className="webinar-icon-add"></span>
                    </div>
                    <div className="text">Add a people</div>
                </div>
                <div className="step-session add-item-control-btn " onClick={() => showImportPeopleModal()}>
                    <div className="icon">
                        <span className="webinar-icon-list"></span>
                    </div>
                    <div className="text">Import people from CSV</div>
                </div>
            </div>

            <div className={`next-step ${states[ 'data' ][ 'step6' ][ 'complete' ] ? '' : 'disabled'}`}
                 onClick={nextStep}>
                Next Step
            </div>
        </div>
    );
};
