import { useState } from 'react';
import * as React from 'react';
import './Step2.scss';
import { Channels } from '../../../components/Channels/Channels';
import { FormDatePicker } from '../../../components/FormDatePicker/FormDatePicker';
import { IconButton } from '../../../components/IconButton/IconButton';
import { GlobalFunctions } from '../../../GlobalFunctions';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStepData,
    update,
    updateEventPeriod,
    addEventPeriod,
    deleteEventPeriod, addChannel, deleteChannel
} from '../../../store/slice/stepData';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

export const Step2: React.FC<{}> = ({}) => {
    const gf = new GlobalFunctions();
    const [formValid, setFormValid] = useState({});
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    let history = useHistory();
    const dataChange = (value, fieldName, subFieldName?) => {
        dispatch(update({
            step: 2,
            fieldName: fieldName,
            subFieldName: subFieldName,
            data: value
        }));
    };
    const addChannelClick = () => {
        dispatch(addChannel({}));
    };

    const valueValidCallback = (status, key) => {
        // setTimeout(() => {
        formValid[ key ] = status;
        setFormValid(formValid);
        dispatch(update({
            step: 2,
            fieldName: 'complete',
            data: checkValueValid()
        }));
        // }, 1);
    };
    const deleteChannelCallback = (index, itemId) => {
        delete formValid[ 'channel' + itemId ];
        setFormValid(formValid);
        dispatch(deleteChannel({
            index: index
        }));
        dispatch(update({
            step: 2,
            fieldName: 'complete',
            data: checkValueValid()
        }));
    };

    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        return true;
    };

    const nextStep = () => {
        if (states[ 'data' ][ 'step2' ][ 'complete' ]) {
            gf.setLocalStorage('step2', states[ 'data' ][ 'step2' ]);
            notification[ 'success' ]({
                message: 'Processing complete'
            });
            history.push('/step/3');
            window.scrollTo(0, 0);
        }
    };
    return (
        <div className="step-page-content-wrapper">
            {states[ 'data' ][ 'step2' ][ 'channel' ].map((v, i) => {
                return (
                    <Channels channelsIndex={i} key={i}
                              valueValid={(value) => valueValidCallback(value, 'channel' + v[ 'itemId' ])}
                              deleteChannel={(value) => deleteChannelCallback(value, v[ 'itemId' ])}/>
                );
            })}
            {states[ 'data' ][ 'step2' ][ 'complete' ] &&
            <div className="step-session add-item-control-btn" onClick={addChannelClick}>
                <div className="icon">
                    <span className="webinar-icon-add"></span>
                </div>
                <div className="text">Add a channel</div>
            </div>
            }

            <div className={`next-step ${states[ 'data' ][ 'step2' ][ 'complete' ] ? '' : 'disabled'}`}
                 onClick={nextStep}>
                Next Step
            </div>
        </div>
    );
};
