import { useState } from 'react';
import * as React from 'react';
import './Step5.scss';
import { FormDatePicker } from '../../../components/FormDatePicker/FormDatePicker';
import { IconButton } from '../../../components/IconButton/IconButton';
import { Sponsor } from '../../../components/Sponsor/Sponsor';
import { GlobalFunctions } from '../../../GlobalFunctions';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectStepData,
    update, deleteSponsor, addSponsor
} from '../../../store/slice/stepData';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

export const Step5: React.FC<{}> = ({}) => {
    const gf = new GlobalFunctions();
    const [formValid, setFormValid] = useState({});
    const states = useSelector(selectStepData);
    const dispatch = useDispatch();
    let history = useHistory();
    const addSponsorClick = () => {
        dispatch(addSponsor({}));
    };

    const valueValidCallback = (status, key) => {
        // setTimeout(() => {
        formValid[ key ] = status;
        setFormValid(formValid);
        dispatch(update({
            step: 5,
            fieldName: 'complete',
            data: checkValueValid()
        }));
        // }, 1);
    };
    const deleteSponsorCallback = (index, itemId) => {
        delete formValid[ 'sponsor' + itemId ];
        setFormValid(formValid);
        dispatch(deleteSponsor({
            index: index
        }));
        dispatch(update({
            step: 5,
            fieldName: 'complete',
            data: checkValueValid()
        }));
    };

    const checkValueValid = () => {
        for (let key in formValid) {
            if (!formValid[ key ]) {
                return false;
            }
        }
        return true;
    };

    const nextStep = () => {
        if (states[ 'data' ][ 'step5' ][ 'complete' ]) {
            gf.setLocalStorage('step5', states[ 'data' ][ 'step5' ]);
            notification[ 'success' ]({
                message: 'Processing complete'
            });
            history.push('/step/6');
            window.scrollTo(0, 0);
        }
    };
    return (
        <div className="step-page-content-wrapper">
            {states[ 'data' ][ 'step5' ][ 'sponsors' ].map((v, i) => {
                return (
                    <Sponsor sponsorIndex={i} key={i}
                             valueValid={(value) => valueValidCallback(value, 'sponsor' + v[ 'itemId' ])}
                             deleteSponsor={(value) => deleteSponsorCallback(value, v[ 'itemId' ])}/>
                );
            })}
            {states[ 'data' ][ 'step5' ][ 'complete' ] &&
            <div className="step-session add-item-control-btn" onClick={addSponsorClick}>
                <div className="icon">
                    <span className="webinar-icon-add"></span>
                </div>
                <div className="text">Add a sponsor</div>
            </div>
            }

            <div className={`next-step ${states[ 'data' ][ 'step5' ][ 'complete' ] ? '' : 'disabled'}`}
                 onClick={nextStep}>
                Next Step
            </div>
        </div>
    );
};
