import React, { Component } from 'react';
import { Header } from './layout/header/Header';
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import { StepPage } from './pages/StepPage/StepPage';

export class App extends Component {

    render() {
        return (
            <Router>
                <div>
                    <Header></Header>
                    <Switch>
                        <Route path="/step">
                            <StepPage></StepPage>
                        </Route>
                        <Route>
                            Not found
                        </Route>
                    </Switch>

                </div>
            </Router>
        );
    }
}

export default App;
