import * as React from 'react';
import style from './IconButton.module.scss';

export const IconButton: React.FC<{ icon: string, text: string }> = ({ icon, text }) => {
    return (
        <div className={style[ 'icon-btn' ]}>
            <div className={`${style.icon}`}>
                <span className={`webinar-icon-${icon}`}></span>
            </div>
            <span className={`${style.text}`}>{text}</span>
        </div>
    );
};
