import { Icon, InputAdornment } from '@material-ui/core';
import { Modal, Steps } from 'antd';
import { useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GlobalFunctions } from '../../GlobalFunctions';
import { addHall, selectStepData, updateHall, deleteHall } from '../../store/slice/stepData';
import './Halls.scss';
import { FormButtonOption } from '../FormButtonOption/FormButtonOption';
import { FormTextField } from '../FormTextField/FormTextField';
import { IconButton } from '../IconButton/IconButton';

export const Halls: React.FC<{
        index: number,
        valueValid?: (value) => any
    }> = ({
              index,
              valueValid = (value) => {
              }
          }) => {
        const gf = new GlobalFunctions();
        const states = useSelector(selectStepData);
        const [formValid, setFormValid] = useState({});
        const [deleteHallIndex, setDeleteHallIndex] = useState(0);
        const [deleteHallItemId, setDeleteHallItemId] = useState('');
        const [canAdd, setCanAdd] = useState(false);
        const [isModalVisible, setIsModalVisible] = useState(false);
        const hallData = states[ 'data' ][ 'step3' ][ 'dates' ][ index ];
        const dispatch = useDispatch();

        console.log(hallData);

        const dataChange = (hallIndex, value, fieldName, subFieldName?) => {
            dispatch(updateHall({
                dateIndex: index,
                hallIndex: hallIndex,
                fieldName: fieldName,
                subFieldName: subFieldName,
                data: value
            }));
        };
        const valueValidCallback = (status, key) => {
            formValid[ key ] = status;
            setFormValid(formValid);
            valueValid(checkValueValid());
            setCanAdd(checkValueValid());
        };
        let selectedChannel = [];

        hallData[ 'halls' ].forEach((v) => {
            selectedChannel = selectedChannel.concat(v.channel);
        });
        let channelOption = [];

        states[ 'data' ][ 'step2' ][ 'channel' ].forEach((v) => {
            channelOption.push({
                label: v.channelName,
                value: v.channelName,
                disable: selectedChannel.indexOf(v.channelName) !== -1
            });
        });

        const checkValueValid = () => {
            for (let key in formValid) {
                if (!formValid[ key ]) {
                    return false;
                }
            }
            return true;
        };

        const addHallHandler = () => {
            dispatch(addHall({
                dateIndex: index
            }));
        };
        const showDeleteHallModel = (i,itemId) => {
            setIsModalVisible(true);
            setDeleteHallIndex(i);
            setDeleteHallItemId(itemId);
        };
        const handleOk = () => {
            setIsModalVisible(false);
            dispatch(deleteHall({
                dateIndex: index,
                hallIndex: deleteHallIndex
            }));
            delete formValid[ 'hallName' + deleteHallItemId ];
            delete formValid[ 'channel' + deleteHallItemId ];
            setFormValid(formValid);
            valueValid(checkValueValid());
            setCanAdd(checkValueValid());
        };

        const handleCancel = () => {
            setIsModalVisible(false);
        };

        return (
            <div className="halls-container">

                {hallData[ 'halls' ].map((v, i) => {
                    return (
                        <div className="step-session" key={i}>
                            <div className="session-title">
                                Hall on {hallData[ 'date' ]}
                            </div>

                            <div className="input-row grid-cols-1">
                                <FormTextField
                                    value={hallData[ 'halls' ][ i ][ 'hallName' ]}
                                    isRequire={true}
                                    label={'Hall name'}
                                    valueValid={(value) => valueValidCallback(value, 'hallName' + v['itemId'])}
                                    valueChange={(value) => dataChange(i, value, 'hallName')}/>
                            </div>
                            <div className="input-row grid-cols-1">

                                <FormButtonOption
                                    value={hallData[ 'halls' ][ i ][ 'channel' ]}
                                    label={'Occupied channel(s)'}
                                    gridSize={'3'}
                                    isRequire={true}
                                    multi={true}
                                    valueValid={(value) => valueValidCallback(value, 'channel' + v['itemId'])}
                                    valueChange={(value) => dataChange(i, value, 'channel')}
                                    option={channelOption}/>
                            </div>

                            {hallData[ 'halls' ].length > 1 &&
                            <div onClick={() => showDeleteHallModel(i,v['itemId'])}>
                                <IconButton icon={'delete'} text={'Delete this hall'}/>
                            </div>
                            }
                        </div>
                    );
                })}

                <Modal title="Delete Hall" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                       okText={'Confirm'}>
                    <p>Confirm to delete this hall?</p>
                </Modal>
                {canAdd &&
                <div className="step-session add-item-control-btn" onClick={addHallHandler}>
                    <div className="icon">
                        <span className="webinar-icon-add"></span>
                    </div>
                    <div className="text">Add a hall on {hallData[ 'date' ]}</div>
                </div>
                }
            </div>
        );
    }
;
