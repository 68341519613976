import { Steps } from 'antd';
import { useState } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectStepData } from '../../store/slice/stepData';
import style from './StepMenu.module.scss';

const { Step } = Steps;
export const StepMenu: React.FC<{}> = ({}) => {
    let history = useHistory();
    const [currentStep, setCurrentStep] = useState(0);
    const [init, setInit] = useState(false);
    const stepStates = useSelector(selectStepData);
    const clickStep = (step) => {
        history.push('/step/' + step);
    };
    history.listen((location, action) => {
        let step = location.pathname.split('/');
        setCurrentStep(parseInt(step[ 2 ]) - 1);
    });

    // console.log(history);
    const checkStepCanAccess = (step) => {
        let complete = true;
        let steps = stepStates[ 'data' ];
        Array(step - 1).fill(1).map((x, y) => x + y).forEach(v => {
            if (!steps[ 'step' + v ][ 'complete' ]) {
                complete = false;
            }
        });
        return complete;
    };
    if (!init) {
        let step = history.location.pathname.split('/');
        setCurrentStep(parseInt(step[ 2 ]) - 1);
        setInit(true);
    }
    return (
        <Steps direction="vertical" current={currentStep}>
            <Step title="Details" onStepClick={() => clickStep('1')}/>

            {['Channels', 'Halls', 'Sessions', 'Sponsors', 'People', 'Audiences', 'Design', 'Legal'].map((v, i) => {
                if (checkStepCanAccess(i + 2)) {
                    return (
                        <Step title={v} onStepClick={() => clickStep(i + 2)} key={i}/>
                    );
                } else {
                    return (
                        <Step title={v} key={i}/>
                    );
                }
            })}
        </Steps>
    );
};
